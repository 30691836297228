import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import CustomButton from "../../components/form/CustomButton";
import { getModules } from "../../helpers/configRoutes/backend_helper";

const ModulesPermissions = ({ modal, setModal, validation }) => {
    const [modules, setModules] = useState([]);
    const [selected,setSelected] = useState(validation.values.modelsPermissions)
    useEffect(() => {
        const getModulesCatalog = async () => {
            const response = await getModules();
            if (response.status) {
                setModules(response.models);
            }
        }
        getModulesCatalog();
    }, []);

    const addModule = (item) => {
        console.log(selected,'selectd')
        if(selected.filter(res => res?.id === item?.id).length == 0){
            setSelected([...selected,item])
        }else{
            let newSelected = selected.filter(res => res?.id != item?.id)
            setSelected(newSelected)
        }
    }

    const save = () => {
        
        validation.setFieldValue('addPermissions',true)
        validation.setFieldValue('modelsPermissions',selected)
        setModal({...modal,show:false})
    }

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                {/* Botones en una cuadrícula adaptable */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                    {modules.map((m, i) => (
                        <CustomButtonOval
                            key={i}
                            type={"success"}
                            onClick={() => addModule(m)}
                            className={`w-full text-sm ${selected.filter(res => res?.id === m?.id).length > 0 ? 'bg-mts-blue-primary text-white' : 'bg-mts-gray-button text-mts-blue-hover'}`}
                        >
                            {m.name}
                        </CustomButtonOval>
                    ))}
                </div>

                {/* Pie del modal */}
                <p className="p-2">*You can choose multiple Order type</p>
                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                    
                    <CustomButton
                        loading={modal.loading}
                        textLoad={modal.textLoad}
                        type={"success"}
                        onClick={save}
                        className="w-full lg:w-[30%]"
                    >
                        {modal.textButton ?? 'Save Preferences'}
                    </CustomButton>
                </div>
            </BaseModalAnimated>

        </>
    )
}

export default ModulesPermissions;