import React from "react";
const StopsList = ({ items,deleteItem }) => {
    return (
        <>
            <table className="w-full text-left text-sm">
                <thead>
                    <tr>
                        <th className="px-4 py-2">No</th>
                        <th className="px-4 py-2">Address</th>
                        <th className="px-4 py-2">Status</th>
                        <th className="px-4 py-2">Mile</th>
                        <th className="px-4 py-2">Load Date</th>
                        <th className="px-4 py-2">Delivery Date</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, i) => (
                        <tr key={i} className="border-b">
                            <td className="px-4 py-2">{item.order}</td>
                            <td className="px-4 py-2">{item.name}</td>
                            <td className="px-4 py-2">{item.status.name}</td>
                            <td className="px-4 py-2">{item.mile}</td>
                            <td className="px-4 py-2">{item.date} {item.load_time}</td>
                            <td className="px-4 py-2">{item.delivery_date} {item.delivery_time}</td>
                            <td className="px-4 py-2 flex space-x-2">
                                <button
                                    type="button"
                                    onClick={() => deleteItem('stops', item)}
                                    className="p-2 hover:bg-gray-100 rounded"
                                >
                                    <i className="fas fa-trash text-red-500"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default StopsList