import React from 'react';
import { useNavigate } from 'react-router-dom';

const Columns = () => {
    const navigate = useNavigate()
    let columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
        },
        {
            id: 'name',
            name: 'NAME',
            selector: row => row.client.contact_name,
        },
        {
            id: 'company',
            name: 'COMPANY',
            selector: row => row.client.name,
        },
        {
            id: 'payment_date',
            name: 'PAYMENT DATE',
            selector: row => row.created_at,
        },
        {
            id: 'total',
            name: 'PAYMENT',
            selector: row => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.total),
        },
        {
            id: 'status',
            name: 'STATUS',
            cell: row => (
                <span
                    className={`px-2 py-1 rounded-full text-white ${row.status.name === "Completed" ? "bg-green-500" : "bg-red-500"
                        }`}
                >
                    {row.status.name}
                </span>
            ),
        },
        
        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => console.log('Editar')} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => console.log('Eliminar')} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                    {/* show details */}
                    <button onClick={() => navigate(`/payment-details/${row.id}`)} className={`flex items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} title='Payment Details'>
                        <i className={`fas fa-eye text-mts-blue-primary`}></i>
                    </button>
                </div>
            ),
        }
    ];
    return columns;
}

export default Columns;