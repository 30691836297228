export const Columns = (openModalAdd,setModalConfirm,modalConfirm ) => {
    let columns = [
        {
            id: 'plate',
            name: 'PLATES',
            cell: row => (
                <>
                    <span>{row.plate_usd} USA</span>
                    <span>{row.plate_mx} MX</span>
                </>
            ),
        },
        {
            id: 'number',
            name: 'TRUCK NUMBER',
            selector: row => row.number,
        },
        {
            id: 'operator',
            name: 'OPERATOR',
            selector: row => row.operator?.name ?? 'Not assigned',
        },
        {
            id: 'model',
            name: 'MODEL',
            selector: row => row.model?.name,
        },
        {
            id: 'fabricant',
            name: 'FABRICANT',
            selector: row => row.model?.fabricant?.name,
        },
        {
            id: 'status',
            name: 'STATUS',
            cell: row => (
                <span className={`px-2 py-1 rounded-full text-white bg-[${row.status?.color}]`}>
                    {row.status?.name}
                </span>
            ),
        },

        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => openModalAdd(row)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: row.id })} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        }
    ];
    return columns;
}

export const ColumnsTrailer = (openModalAdd, setModalConfirm, modalConfirm) => {
    let columns = [
        {
            id: 'plate',
            name: 'PLATES',
            cell: row => (
                <>
                    <p><span>{row.plate_usd} USA</span></p>
                    <p><span>{row.plate_mx} MX</span></p>
                </>
            ),
        },
        {
            id: 'vin',
            name: 'VIN NUMBER',
            selector: row => row.vin,
        },
        {
            id: 'type',
            name: 'TYPE',
            selector: row => row.type?.name,
        },
        {
            id: 'model',
            name: 'MODEL',
            selector: row => row.model?.name,
        },
        {
            id: 'fabricant',
            name: 'FABRICANT',
            selector: row => row.model?.fabricant?.name,
        },
        {
            id: 'status',
            name: 'STATUS',
            cell: row => (
                <span className={`px-2 py-1 rounded-full text-white bg-[${row.status?.color}]`}>
                    {row.status?.name}
                </span>
            ),
        },

        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => openModalAdd(row)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: row.id })} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        }
    ];
    return columns;
}
