import React, { useEffect, useState } from "react";
import CustomSelect from "../form/CustomSelect";
import "react-datepicker/dist/react-datepicker.css"; // Importar los estilos necesarios
import SingleCalendarDateRangePicker from "../form/SingleCalendarDateRangePicker";
import { getStatuses, getTypes, plansResource, modelsTruckTrailerResource } from "../../helpers/configRoutes/backend_helper";


const FiltersPagination = ({ meta, children, filters, setFilters, showFilters, statusModel }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [plans, setPlans] = useState([]);
    const [types, setTypes] = useState([]);
    const [models, setModels] = useState([]);
    useEffect(() => {
        const getFilters = async () => {
            if (statusModel == 'TrailerTruck') {
                const typesResponse = await getTypes('get', { params: { select: true } });
                setTypes(typesResponse.types)
                const modelsResponse = await modelsTruckTrailerResource('get', { params: { select: true } });
                setModels(modelsResponse.models)
            } else {
                const plansResponse = await plansResource('get', { params: { select: true } });
                if (plansResponse.status) {
                    setPlans(plansResponse.plans)
                }
            }
            const statusesResponse = await getStatuses({ params: { model: (statusModel ?? 'Subscription') } })
            if (statusesResponse.status) {
                setStatuses(statusesResponse.statuses)
            }
        };
        if (showFilters) {
            getFilters();
        }
    }, [showFilters]);

    return (
        <>
            {showFilters &&
                <div className="flex w-full mb-6 ">
                    {/* Contenedor de los filtros - ocupa el 50% */}
                    <div className="flex items-center bg-mts-button-gray border rounded-lg p-3 space-x-4 w-2/3 relative">
                        {/* Filtro de fecha */}
                        <div className="flex items-center space-x-2 border-r border-gray-100 pr-4">
                            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 9.75C16.3848 9.75 20.75 7.73528 20.75 5.25C20.75 2.76472 16.3848 0.75 11 0.75C5.61522 0.75 1.25 2.76472 1.25 5.25C1.25 7.73528 5.61522 9.75 11 9.75Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1.25 5.25C1.25253 9.76548 4.35614 13.688 8.75 14.729V21C8.75 22.2426 9.75736 23.25 11 23.25C12.2426 23.25 13.25 22.2426 13.25 21V14.729C17.6439 13.688 20.7475 9.76548 20.75 5.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>

                        {/* Selector de rango de fechas */}
                        {statusModel != 'TrailerTruck' &&


                            <div className="relative">
                                {!showCalendar ? (
                                    <div className="flex items-center space-x-2 border-r border-gray-100 pr-4" onClick={() => setShowCalendar(!showCalendar)}>
                                        <div>
                                            <span>Date:</span>
                                            {(filters.startDate && filters.endDate) && (
                                                <p>{filters.startDate.toLocaleDateString()} to {filters.endDate.toLocaleDateString()}</p>
                                            )}
                                        </div>
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="absolute top-full mt-2 left-0 z-50 bg-white shadow-lg p-4 border border-gray-200">
                                        <SingleCalendarDateRangePicker filters={filters} setFilters={setFilters} setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
                                        <div className="mt-2">
                                            {filters.startDate && <p>Start Date: {filters.startDate.toLocaleDateString()}</p>}
                                            {filters.endDate && <p>End Date: {filters.endDate.toLocaleDateString()}</p>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        {/* Otros filtros */}
                        <div className="flex items-center space-x-2 border-r border-gray-100 pr-4">
                            {statusModel == 'TrailerTruck' ?
                                <CustomSelect className={'border-none'} options={types} holder={'Type'} value={filters.type_id} onChange={(e) => setFilters({ ...filters, type_id: e.target.value })} />
                                :
                                <>
                                {statusModel != 'Settlement' &&
                                    <CustomSelect className={'border-none'} options={plans} holder={'Subscription'} value={filters.plan_id} onChange={(e) => setFilters({ ...filters, plan_id: e.target.value })} />
  
                                }
                                </>
                            }

                        </div>
                        <div className="flex items-center space-x-2">
                            <CustomSelect className={'border-none'} options={models} holder={'Model'} value={filters.model_id} onChange={(e) => setFilters({ ...filters, model_id: e.target.value })} />
                        </div>
                        <div className="flex items-center space-x-2">
                            <CustomSelect className={'border-none'} options={statuses} holder={'Status'} value={filters.status_id} onChange={(e) => setFilters({ ...filters, status_id: e.target.value })} />
                        </div>

                        {/* Botón de reset */}
                        <div className="flex items-center space-x-2 text-mts-red cursor-pointer" onClick={() => setFilters({ ...filters, search: '', plan_id: '', status_id: '', startDate: '', endDate: '', model_id: '', type_id: '' })}>
                            <i className="fa fa-repeat"></i>
                            <span>Reset Filter</span>
                        </div>
                    </div>

                    {/* Input de búsqueda - ocupa el otro 50% */}
                    {statusModel != 'TrailerTruck' &&
                        <div className="relative w-1/3 p-4">
                            <input
                                type="text"
                                placeholder="Search"
                                value={filters.search}
                                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                className="pl-10 pr-4 py-2 w-full bg-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <svg className="fas fa-search absolute left-7 top-7 text-gray-400" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <g opacity="0.5">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.69341 12.5352C12.4232 11.3751 13.6957 8.2216 12.5356 5.49177C11.3754 2.76193 8.22196 1.48945 5.49212 2.6496C2.76229 3.80975 1.48981 6.96321 2.64996 9.69305C3.81011 12.4229 6.96357 13.6954 9.69341 12.5352Z" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.3901 11.3896L15.5555 15.5556" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                        </div>
                    }
                </div>

            }

            {children}
            <div className="flex items-center justify-between py-2">
                {/* Showing results text */}
                <div className="text-gray-500">
                    Showing <span className="font-semibold">{meta.from}-{meta.to}</span> of <span className="font-semibold">{meta.total}</span>
                </div>

                {/* Pagination arrows */}
                <div className="flex items-center space-x-1">
                    {/* Previous Button */}
                    <button className="flex items-center justify-center w-8 h-8 border rounded-lg hover:bg-gray-200 focus:outline-none" disabled={filters.page == 1} onClick={() => setFilters({ ...filters, page: (filters.page - 1) })}>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>

                    {/* Next Button */}
                    <button className="flex items-center justify-center w-8 h-8 border rounded-lg hover:bg-gray-200 focus:outline-none" disabled={meta.last_page == filters.page} onClick={() => setFilters({ ...filters, page: (filters.page + 1) })}>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default FiltersPagination;