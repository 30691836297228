import React, { useEffect, useState } from "react";
import { usersResource } from "../../helpers/configRoutes/backend_helper";
import { Link, useNavigate } from "react-router-dom";
import PreviewPDF from "../../components/modal/PreviewPdf";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import IconAlert from "../../components/elements/IconAlert";

const Operators = () => {
    const navigate = useNavigate();
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete user', loadig: true, textLoad: 'Processing' })
    const [filters, setFilters] = useState({ perPage: 5, page: 1, operator: true });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [modalPdf, setModalPdf] = useState({ show: false, url: '' })
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const getUsers = async () => {
            const response = await usersResource('get', { params: filters });
            if (response.status) {
                setUsers(response.users)
            }
        }
        getUsers()
    }, []);

    const deleteUser = async () => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await usersResource('del', {}, modalConfirm.id);
        if (response.status) {
            setUsers(response.users)
            let newArray = users.filter((u) => u.id != modalConfirm.id)
            setUsers(newArray);
        }
        setModalConfirm({ ...modalConfirm, loading: false, show: false, id: null })
    }

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Operator</h1>
                    <Link to={'/operator-upadd'} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                        + Add New Operator
                    </Link>
                </div>

                {/* Team Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {users.map((u, i) => (
                        <div
                            className="bg-white rounded-xl shadow-md p-6 relative flex flex-col justify-between"
                            key={i}
                        >
                            {/* Active status indicator */}
                            <span
                                className={`absolute top-4 left-4 w-4 h-4 ${u.active ? "bg-mts-green" : "bg-mts-red"
                                    } rounded-full`}
                            ></span>

                            {/* Profile image */}
                            <img
                                src={u.imageProfile || "./images/usrAnonimo.png"}
                                alt="Portrait"
                                className="w-24 h-24 rounded-full mx-auto"
                            />

                            {/* User info */}
                            <div className="text-center mt-4">
                                <h2 className="font-semibold text-lg mb-2">
                                    {u.first_name} {u.last_name}
                                </h2>
                                <p className="text-gray-500 mb-2">{u.email}</p>
                            </div>

                            {/* License Section */}
                            <div className="mt-4 flex items-start justify-between">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-600">
                                        Driver License
                                    </h3>
                                    <p className="text-gray-500 text-sm">
                                        Expiration: {u.license_expiration}
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        {u.license_expiration_days} days remaining
                                    </p>
                                    <button onClick={() =>setModalPdf({show: true,url: u.driver_license,title: "Driver License"})} className="flex items-center justify-center text-mts-blue-primary mt-2 hover:underline">
                                        <i className="fas fa-eye mr-1"></i> View
                                    </button>
                                </div>
                                <IconAlert days={u.license_expiration_days} textAlert={`${u.name}: Driver License is about to expire!`}/>
                                
                            </div>

                            {/* DOT Physical Section */}
                            <div className="mt-4 flex items-start justify-between">
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-600">
                                        DOT Physical
                                    </h3>
                                    <p className="text-gray-500 text-sm">
                                        Expiration: {u.dot_physical_expiration}
                                    </p>
                                    <p className="text-gray-500 text-sm">
                                        {u.dot_physical_expiration_days} days remaining
                                    </p>
                                    <button onClick={() =>setModalPdf({show: true,url: u.dot_physical_certificate,title: "DOT Physical"})} className="flex items-center justify-center text-mts-blue-primary mt-2 hover:underline">
                                        <i className="fas fa-eye mr-1"></i> View
                                    </button>
                                </div>
                                <IconAlert  days={u.dot_physical_expiration_days} textAlert={`${u.name}: DOT Physical is about to expire!`}/>
                                {/* Alert Icon */}
                                
                            </div>

                            {/* Action buttons fixed at the bottom */}
                            <div className="flex space-x-2 rounded-lg shadow-sm justify-center mt-auto pt-4">
                                <button
                                    onClick={() => navigate(`/operator-upadd/${u.id}`)}
                                    className={`flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100 `}
                                >
                                    <i className="fas fa-pen text-gray-500"></i>
                                </button>
                                <button
                                    onClick={() =>
                                        setModalConfirm({ ...modalConfirm, show: true, id: u.id })
                                    }
                                    className={`flex items-center justify-center px-4 py-2 ${!u.can?.delete ? "" : "hover:bg-gray-100"
                                        }`}
                                    disabled={!u.can?.delete}
                                >
                                    <i
                                        className={`fas fa-trash ${!u.can?.delete
                                            ? "opacity-50"
                                            : "text-red-500"
                                            }`}
                                    ></i>
                                </button>
                            </div>
                            <PreviewPDF modal={modalPdf} setModal={setModalPdf} />
                        </div>
                    ))}
                </div>

            </div>
            <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deleteUser} />
        </>
    )
}

export default Operators;
