import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logOut } from "../../helpers/configRoutes/backend_helper";

const Header = () => {
    const navigate = useNavigate()
    const user = useSelector((state) => state.Login.user);
    const [menuItems, setMenuItems] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Controla si el menú está abierto
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false); // Controla el menú de perfil
    const location = useLocation();

    useEffect(() => {
        if (user.id) {
            setMenuItems(user.models);
        }
    }, [user]);

    // Función para alternar el menú en pantallas pequeñas
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Alternar el menú de perfil
    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    // Cerrar el menú cuando cambie la ubicación (ruta)
    useEffect(() => {
        setIsMenuOpen(false);
        setIsProfileMenuOpen(false);
    }, [location]);

    // Función de cierre de sesión (puedes adaptarla según tu lógica de autenticación)
    const handleLogout = async () => {
        // Llamar a la función de cierre de sesión
        const response = await logOut();
        // Eliminar los datos del localStorage
        localStorage.removeItem('isAuth');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // Redirigir al usuario a la página de inicio de sesión o a otra página
        // Puedes utilizar history.push("/login") si usas react-router
        navigate('/login'); // O usa una redirección
    };

    return (
        <header className="flex justify-between items-center p-3 border-b bg-mts-white border-mts-white relative">
            <div className="absolute top-0 left-0 w-full h-1"></div>

            {/* Botón de menú hamburguesa */}
            <button onClick={toggleMenu} className="text-2xl lg:hidden">
                <span className="material-symbols-outlined">menu</span>
            </button>
            <div className="relative ml-4 hidden lg:block">
                
            </div>
            {/* Perfil de usuario */}
            <div className="relative">
                <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleProfileMenu}>
                    <img src={user.imageProfile || "./images/usrAnonimo.png"} alt="Profile picture" className="w-10 h-10 rounded-full" />
                    <div>
                        <p className="font-semibold text-gray-700">{user.first_name}</p>
                        <p className="text-sm text-gray-500">{user.role}</p>
                    </div>
                    <i className={`fas fa-chevron-down text-gray-500 ${isProfileMenuOpen ? 'rotate-180' : ''}`}></i>
                </div>

                {/* Menú desplegable de perfil */}
                {isProfileMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                        <Link to="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                            View Profile
                        </Link>
                        <button
                            onClick={handleLogout}
                            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>

            {/* Menú lateral visible en pantallas pequeñas */}
            <div
                className={`${isMenuOpen ? 'block' : 'hidden'} absolute top-full left-0 w-full bg-mts-white border-t border-mts-gray lg:hidden`}
            >
                {menuItems.map((item, index) => {
                    const isActive = location.pathname === item.link || (item.associatedRoutes && item.associatedRoutes.includes(location.pathname));

                    return (
                        <Link
                            to={item.link}
                            key={index}
                            className={`relative flex flex-row justify-between items-center px-4 py-2 hover:bg-o2o-gray-alt hover:underline hover:text-base rounded-lg transition-colors duration-200 ${isActive
                                ? 'bg-blue-500 text-white before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-blue-700'
                                : ''
                                }`}
                            onClick={() => setIsMenuOpen(false)} // Cierra el menú al hacer clic en una opción
                        >
                            <div className="w-10">
                                <span className="material-symbols-outlined">{item.icon}</span>
                            </div>
                            <div className="flex-1">{item.name}</div>
                        </Link>
                    );
                })}
            </div>
        </header>
    );
};

export default Header;
