import { useNavigate } from "react-router-dom";

export const Columns = (modalConfirm, setModalConfirm,filters) => {
    const navigate = useNavigate()
    let columns = [
        {
            id: 'no_settlement',
            name: 'NO.',
            selector: row => row.no_settlement
        },
        {
            id: 'operator',
            name: filters.type==1 ? 'CLIENT':'OPERATOR',
            selector: row => row.item_name
        },
        {
            id: 'type',
            name: 'TYPE',
            selector: row => row.type == 1 ? 'Company' : 'Operator'
        },
        {
            id: 'total_amount',
            name: 'AMOUNT',
            selector: row => `$${Number(row.total_amount).toLocaleString('usd')}`
        },
        {
            id: 'date',
            name: 'DATE',
            selector: row => row.created_at
        },
        {
            id: 'due_date',
            name: 'DUE DATE',
            selector: row => row.due_date
        },
        {
            id: 'action',
            name: 'ACTION',
            cell: row => (
                <>
                    <button onClick={() => navigate(`/account-settlement/${row.id}`)} className={`flex items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} title='Payment Details'>
                        <i className={`fas fa-eye text-mts-blue-primary`}></i>
                    </button>
                </>
            ),
        },
    ];
    return columns;

}
