import React from "react";
import FileInput from "../../components/form/FileInput";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import { showFormErrors } from "../../helpers/showFormErrors";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/form/CustomInput";
import CustomButton from "../../components/form/CustomButton";
 
const UpFile = ({ validation, modal, setModal }) => {
    const validationDocument = useFormik({
        initialValues: {
            description: '',
            document: ''
        },
        validationSchema: Yup.object({
            document: Yup.string().required('Document is required'),
            description: Yup.string().required('Description'),
        }),
        onSubmit: async (values, { setErrors, resetForm }) => {
            validation.setFieldValue('evidences', [
                ...(validation.values?.evidences || []),
                {
                    id:values.id || `random-${Math.random().toString(36).substr(2, 9)}`,
                    name: values.name_file_input,
                    base64: values.document,
                    document_format: values.document_format,
                    description: values.description || 'Sin descripción'
                }
            ]);
            resetForm()
            setModal({ ...modal, show: false })
        }
    });

    return (
        <BaseModalAnimated modal={modal} setModal={setModal}>
            <div className="grid grid-cols-2 gap-4 p-2">
                {modal.type === 'camera' ?
                    <>
                    </>
                    :
                    <FileInput
                        validation={validationDocument}
                        format={modal.type}
                        name={'document'}
                        nameInput={"Evidence"}
                    />
                }

                <CustomInput
                    label="Description"
                    type="text"
                    name="description"
                    labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                    error={validationDocument?.errors?.description}
                    value={validationDocument?.values?.description}
                    handleChange={validationDocument.handleChange}
                />
            </div>
            <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                <CustomButton
                    type={"button"}
                    onClick={() => [validationDocument.handleSubmit(), showFormErrors(validationDocument)]}
                    className="w-full lg:w-[30%]"
                >
                    Add Evidence
                </CustomButton>
            </div>
        </BaseModalAnimated >
    )
}

export default UpFile