import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/form/CustomButton";
import FiltersPagination from "../../../components/elements/FiltersPagination";
import DataTableElement from "../../../components/elements/DataTableElement";
import { Columns } from "./Columns";
import { Navigate, useNavigate } from "react-router-dom";
import { settlementsResource } from "../../../helpers/configRoutes/backend_helper";
import ConfirmDeleteModal from "../../../components/modal/ConfirmDeleteModal";

const AccountingSettlements = () => {
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '', from: 0, to: 0, page: 1 });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading settlements...' });
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Truck', loadig: false, textLoad: 'Processing' })
    const cols = Columns(modalConfirm, setModalConfirm,filters);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await settlementsResource('get', { params: filters });
            setData(response.settlements);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false })
        };
        getData();
    }, [filters]);


    return (
        <>
            <div className="p-8">
                <div className="text-2xl font-semibold mb-4">Settlement</div>
                <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'Settlement'}>
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                </FiltersPagination>
            </div>
        </>
    );
}

export default AccountingSettlements;