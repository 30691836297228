import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FiltersPagination from "../../../components/elements/FiltersPagination";
import DataTableElement from "../../../components/elements/DataTableElement";
import { Columns } from "./Columns";
import CustomButton from "../../../components/form/CustomButton";
import { clientsResource, loadsResource, settlementsResource, usersResource } from "../../../helpers/configRoutes/backend_helper";
import EvidencesFilesModal from "../../Loads/EvidencesFilesModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../../../components/form/CustomSelect";
import { showFormErrors } from "../../../helpers/showFormErrors";

const AddSettlements = () => {
    const navigate = useNavigate();
    const {id} = useParams()
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '', from: 0, to: 0, page: 1, truck: true, settlement: true });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading accounts...' });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalFiles, setModalFiles] = useState({ show: false, item: {}, title: 'Show files', });
    const [clients, setClients] = useState([]);
    const [operators, setOperators] = useState([]);

    const validation = useFormik({
        initialValues: {
            id: null,
            type: '',
            item_id: '',
            loads: []
        },
        validationSchema: Yup.object({
            type: Yup.string().required('The type field is required'),
            item_id: Yup.string()
                .required('The item_id field is required'),
            loads: Yup.array().min(1)
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await settlementsResource(values.id ? 'put':'post',values,values.id)
            if(response.status){
                setLoading(false)
                navigate('/settlements');
            }
            setLoading(false)
        },
    });

    const handleCheckboxChange = (element, item) => {
        if (element.target.checked) {
            validation.setFieldValue('loads', [...validation.values.loads, item.id]);
        } else {
            validation.setFieldValue('loads', validation.values.loads.filter(data => data.id !== item.id));
        }
    };


    const cols = Columns(handleCheckboxChange, setModalFiles, modalFiles,validation);

    useEffect(() => {
        const getData = async () => {
            setTableLoad({ ...tableLoad, show: true });
            const response = await loadsResource('get', { params: filters });
            setData(response.loads);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false });
        };
        if (validation.values.type != null && validation.values.item_id) {
            getData();
        }
    }, [filters, validation.values.type, validation.values.item_id]);

    useEffect(() => {
        const getCatalogs = async () => {
            const responseClient = await clientsResource('get', { params: { select: true } })
            setClients(responseClient.clients)
            const responseOperators = await usersResource('get', { params: { operator: true, select: true } })
            setOperators(responseOperators.users)
        };
        getCatalogs();
    }, []);

    useEffect(() => {
        const getSettlement = async () => {
            const response = await settlementsResource('show',{},id)
            validation.setValues(response.settlement)
        };
        if(id){
            getSettlement();
        }
    }, [id]);

    const changeFilters = (id, operator = false) => {
        if (operator) {
            setFilters({ ...filters, operator_id: id, client_id: '' })
            validation.setFieldValue('item_id', id)
        } else {
            setFilters({ ...filters, client_id: id, operator_id: '' })
            validation.setFieldValue('item_id', id)
        }
    }

    return (
        <>
            <div className="flex items-center space-x-2 p-4">
                <button
                    onClick={() => navigate('/settlements')}
                    className="text-gray-600 hover:text-gray-800"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
                <h1 className="text-lg font-medium text-gray-800">Create New Settlement</h1>
            </div>

            <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'Settlement'}>
                <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <CustomSelect
                            label="Settlement Type"
                            options={[{ id: 1, name: 'Company Settlement' }, { id: 2, name: 'Operator Settlement' }]}
                            name="type"
                            labelClassName={'text-base'}
                            error={validation?.errors?.type}
                            value={validation?.values?.type}
                            handleChange={(e) => [validation.setFieldValue('type', e.target.value), validation.setFieldValue('item_id', ''), validation.setFieldValue('loads', []), setData([])]}
                        />
                        {validation.values.type == 1 &&
                            <CustomSelect
                                label="Clients"
                                options={clients}
                                name="item_id"
                                labelClassName={'text-base'}
                                error={validation?.errors?.item_id}
                                value={validation?.values?.item_id}
                                handleChange={(e) => changeFilters(e.target.value, false)}
                            />
                        }

                        {validation.values.type == 2 &&
                            <CustomSelect
                                label="Operators"
                                options={operators}
                                name="item_id"
                                labelClassName={'text-base'}
                                error={validation?.errors?.item_id}
                                value={validation?.values?.item_id}
                                handleChange={(e) => changeFilters(e.target.value, true)}
                            />
                        }

                    </div>
                </div>
                <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                <div className="flex justify-center mt-4">
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            showFormErrors(validation);
                            return false;
                        }}
                    >
                        <CustomButton
                            type={'submit'}
                            loading={loading}
                            textLoad={'Saving'}
                            className="bg-mts-blue-primary text-white px-4 py-2 rounded-lg hover:bg-mts-blue-hover"
                        >
                            Create Settlement
                        </CustomButton>
                    </form>
                </div>
            </FiltersPagination>
            <EvidencesFilesModal modal={modalFiles} setModal={setModalFiles} />
        </>
    );
};
export default AddSettlements;
