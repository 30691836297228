import React, { useEffect, useState } from "react";
import FiltersPagination from "../../components/elements/FiltersPagination";
import DataTableElement from "../../components/elements/DataTableElement";
import { trailerTrucksResource } from "../../helpers/configRoutes/backend_helper";
import { ColumnsTrailer } from "./Columns";
import CustomButton from "../../components/form/CustomButton";
import { useNavigate } from "react-router-dom";
import AddUpdTrailerTruckModal from "./AddUpdTrailerTruck";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";

const Trailers = () => {
    const navigate = useNavigate()
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '', from: 0, to: 0, page: 1,truck:'' });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading accounts...' });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [data, setData] = useState([]);
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Trailer', loadig: false, textLoad: 'Processing' })
    const [modal, setModal] = useState({ show: false });
    const [trailerTruckItem, setTrailerTruckItem] = useState({ id: null });

    useEffect(() => {
        const getData = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await trailerTrucksResource('get', { params: filters });
            setData(response.items);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false })
        };
        getData();
    }, [filters]);
    
    const openModalAdd = (trailerTruckItem = {}) => {
        setTrailerTruckItem(trailerTruckItem)
        setModal({ ...modal, show: true, title: trailerTruckItem.id ? "Edit Trailer" : 'Add Trailer',truck:false })
    };

    const deleteTrailerTruck = async() => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await trailerTrucksResource('del', {},modalConfirm.id);
        if (response.status) {
            setFilters({...filters})
        }
        setModalConfirm({ ...modalConfirm, loading: false,show:false,id:null })
    }

    const cols = ColumnsTrailer(openModalAdd,setModalConfirm,modalConfirm)
    return (
        <div className="p-8">
            <div className="text-2xl font-semibold mb-4">Trailers</div>
            <div className="flex justify-end font-semibold mb-4">
                <CustomButton onClick={()=>openModalAdd()} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                    + Add New Trailer
                </CustomButton>
            </div>

            <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'TrailerTruck'}>
                <div className="bg-white shadow-md rounded-lg">
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                </div>
            </FiltersPagination>
            <AddUpdTrailerTruckModal modal={modal} setModal={setModal} trailerTruckItem={trailerTruckItem} setFilters={setFilters} filters={filters}/>
            <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deleteTrailerTruck} />
        </div>
        
    )
}

export default Trailers;