import React, { useEffect, useState } from "react";
import { clientsCompanyResource, plansActivate, plansResource } from "../../helpers/configRoutes/backend_helper";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";
import CustomButtonOval from "../../components/form/CustomButtonOval";
import FiltersPagination from "../../components/elements/FiltersPagination";
import { currencyUSD } from "../../helpers/forms/formater";
import { useSelector } from "react-redux";

const Suscription = () => {
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Subscriptions', loadig: false, textLoad: 'Processing', user: true })
    const [filters, setFilters] = useState({ perPage: 3, page: 1, search: '' });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [plans, setPlans] = useState([]);
    const user = useSelector((state) => state.Login.user);
    const [company,setCompany]= useState({})

    useEffect(() => {
        const getPlans = async () => {
            const response = await plansResource('get', { params: filters });
            setPlans(response.plans);
            setMeta(response.meta);
        };
        getPlans();
    }, [filters]);

    useEffect(() => {
        const getDataCompany = async () => {
            const response = await clientsCompanyResource('show', {}, user.company_id);
            setCompany(response.company)
        }
        getDataCompany();
    }, []);

    const deletePlan = async () => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await plansResource('del', {}, modalConfirm.id);
        if (response.status) {
            setFilters({ ...filters })
        }
        setModalConfirm({ ...modalConfirm, loading: false, show: false, id: null })
    }

    const activate = async (item) => {
        const response = await plansActivate(item);
        if (response.status) {
            setFilters({ ...filters });
        }
    }

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-[32px] font-bold">Account</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {plans.map((plan) => (
                        <div key={plan.id} className="bg-white rounded-xl shadow-lg p-6 text-center">
                            <h3 className="font-bold text-lg">{plan.name}</h3>
                            <p className="text-[46px] text-[#4880FF] font-bold">
                                {plan.price}
                            </p>
                            <hr className="border-t-2 border-gray-300 my-4" />
                            <div className="mt-[94px] space-y-4 text-xlg">
                                <p>${plan.price} USD</p>
                                <p>{currencyUSD(parseFloat(plan.price) == 0 ? 0 : ((plan.price) * (plan.taxes / 100)))} TAXES</p>
                                <p>{plan.trucks} TRUCKS</p>
                                <p>{plan.users} USERS</p>
                            </div>
                            <hr className="border-t-2 border-gray-300 mt-[147px] my-4" />
                            <div className="flex justify-center mt-[40px]">
                                <CustomButtonOval onClick={() => console.log(plan)} isDisabled={!plan?.can?.activate} className={company.plan_id == plan.id ? 'bg-blue-500 text-white' : 'bg-mts-gray-button border border-mts-blue-primary text-mts-blue-hover '}>
                                    {company.plan_id==plan.id ? 'Current Subscription':(company.plan_id < plan.id ? 'Ugrade':'Downgrade')}
                                </CustomButtonOval>
                            </div>
                            <p className="text-base  underline">{plan.day_trial} Day Trial</p>
                        </div>
                    ))}
                </div>
                <FiltersPagination filters={filters} setFilters={setFilters} showFilters={false} meta={meta} />
            </div>
            <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deletePlan} />
        </>
    )
}

export default Suscription;