import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getStatuses, trailerTrucksResource, usersResource, getPrefixes, getScheduls, clientsResource, loadsResource } from "../../helpers/configRoutes/backend_helper";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";
import AddStopsModal from "./AddStopsModal";
import PreviewPDF from "../../components/modal/PreviewPdf"
import FileUploader from "./FileUpdater";
import StopsList from "./StopsList";

const AddUpdLoadModal = ({ modal, setModal, trailerTruckItem, setFilters, filters }) => {
    const [trucks, setTrucks] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [statuses, setStatuses] = useState([])
    const [clients, setClients] = useState([])
    const [loadSchedules, setLoadSchedules] = useState([])
    const [operators, setOperators] = useState([])
    const [modalStops, setModalStops] = useState({ show: false, title: 'Add Stops' })
    const [prefixes, setPrefixes] = useState([]);
    const [loading, setLoading] = useState(false)
    const [modalPrev, setModalPrev] = useState({ show: false, url: '' })
    const baseitem = {
        id: null,
        charges: '',
        client_id: '',
        practical_miles: '',
        status_id: '',
        schedule_id: '',
        operator_id: '',
        truck_id: '',
        trailer_id: '',
        stops: [],
        evidences: []
    }

    const validation = useFormik({
        initialValues: trailerTruckItem,
        validationSchema: Yup.object({
            charges: Yup.number()
                .required("Charges are required")
                .positive("Charges must be a positive number"),
            client_id: Yup.string().required("Client is required"),
            practical_miles: Yup.string().required("Status is required"),
            status_id: Yup.string().required("Status is required"),
            schedule_id: Yup.string().required("Load schedule is required"),
            operator_id: Yup.string()
                .required("Operator is required"),
            truck_id: Yup.string()
                .required("Truck is required"),
            trailer_id: Yup.string()
                .required("Trailer is required"),
            stops: Yup.array()
                .of(
                    Yup.object().shape({
                        order: Yup.number().required("Order is required"),
                        name: Yup.string().required("Stop name is required"),
                        status: Yup.object().shape({
                            name: Yup.string().required("Status name is required")
                        })
                    })
                )
                .required("At least one stop is required"),
            evidences: Yup.array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required("File name is required")
                    })
                )
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await loadsResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                setLoading(false)
                setFilters({ ...filters, search: filters.search })
                setModal({ ...modal, show: false })
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    useEffect(() => {
        if (trailerTruckItem.id) {
            validation.setValues(trailerTruckItem); // Actualizar los valores del formulario cuando `client` cambia
        } else {
            validation.resetForm({ values: baseitem }); // Resetea el formulario a los valores por defecto si no hay cliente
        }
    }, [modal]);

    useEffect(() => {
        const getCatalogs = async () => {
            //const clientsResponse = await clientsOfficeResource('get', { params: { select: true } });
            //setClients(clientsResource.types)
            const trucksResponse = await trailerTrucksResource('get', { params: { select: true, truck: true } })
            setTrucks(trucksResponse.items)

            const trailerResponse = await trailerTrucksResource('get', { params: { select: true } })
            setTrailers(trailerResponse.items)

            const operatorsResponse = await usersResource('get', { params: { operator: true, selectLoad:true } });
            setOperators(operatorsResponse.users)

            const statusesResponse = await getStatuses({ params: { model: 'Load' } })
            setStatuses(statusesResponse.statuses)

            const prefixesResponse = await getPrefixes();
            setPrefixes(prefixesResponse.prefixes);

            const schedulsResponse = await getScheduls()
            setLoadSchedules(schedulsResponse.scheduls)

            const clientResponse = await clientsResource('get', { params: { select: true } })
            setClients(clientResponse.clients)
        }
        if(modal.show){
            getCatalogs()
        }
    }, [modal]);

    useEffect(() => {
        let total = 0;
        validation.values?.stops?.map((s, i) => {
            total = parseFloat(s.mile) + parseFloat(total)
        });
        console.log(validation.values.stops, 'stops')
        // Actualiza el campo 'practical_miles' en Formik
        validation.setFieldValue('practical_miles', total.toFixed(2)); // Redondea a 2 decimales
    }, [validation.values.stops]);

    const deleteItem = (list, item) => {
        if (list == 'evidences') {
            const array = validation.values.evidences
            const evidencesDeleted = validation.values.evidences_deleted || [];
            let newArray = array.filter((f) => {
                if (f.id != item.id) {
                    return f
                }
            })
            evidencesDeleted.push(item.id);
            validation.setFieldValue('evidences_deleted', evidencesDeleted);
            validation.setFieldValue('evidences', newArray);
        }
        if (list == 'stops') {
            const array = validation.values.stops
            const stopsDeleted = validation.values.stops_deleted || [];
            let newArray = array.filter((f) => {
                if (f.order != item.order) {
                    return f
                }
            })
            stopsDeleted.push(item.id);
            validation.setFieldValue('stops_deleted', stopsDeleted);
            validation.setFieldValue('stops', newArray);
        }
    }

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4 p-2">
                        <div className="grid grid-cols-2">
                            <CustomInput
                                label="Charges"
                                type="text"
                                name="charges"
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.charges}
                                value={validation?.values?.charges}
                                handleChange={validation.handleChange}
                            />
                            <CustomSelect
                                label="Client"
                                options={clients}
                                name="client_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.client_id}
                                value={validation?.values?.client_id}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <div className="grid grid-cols-2 ">
                            <CustomSelect
                                label="Status"
                                options={statuses}
                                name="status_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.status_id}
                                value={validation?.values?.status_id}
                                handleChange={validation.handleChange}
                            />
                            <CustomSelect
                                label="Load Schedule"
                                options={loadSchedules}
                                name="schedule_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.schedule_id}
                                value={validation?.values?.schedule_id}
                                handleChange={validation.handleChange}
                            />
                        </div>
                        <CustomSelect
                            label="Operator"
                            options={operators}
                            name="operator_id"
                            labelClassName={'text-base text-[#ADADAD]'}
                            className={'mr-2'}
                            error={validation?.errors?.operator_id}
                            value={validation?.values?.operator_id}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-2 ">
                            <CustomSelect
                                label="Truck"
                                options={trucks}
                                name="truck_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.truck_id}
                                value={validation?.values?.truck_id}
                                handleChange={validation.handleChange}
                            />
                            <CustomSelect
                                label="Trailer"
                                options={trailers}
                                name="trailer_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.trailer_id}
                                value={validation?.values?.trailer_id}
                                handleChange={validation.handleChange}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 p-2">
                        <div className="grid grid-cols-1 " >
                            <label
                                onClick={() => {if (validation.values.truck_id) {setModalStops({ ...modalStops, show: true });}}}
                                className={`text-mts-blue-primary ml-3 mt-0 ${validation.values.truck_id ? 'cursor-pointer hover:underline': 'cursor-not-allowed text-gray-400'} mb-0`}>
                                + Stops
                            </label>
                            <div className="grid grid-cols-1  bg-mts-gray w-full" >
                                <StopsList deleteItem={deleteItem} items={validation.values?.stops || []} />
                            </div>
                        </div>
                        <CustomInput
                            label="Practical Miles"
                            type="text"
                            name="practical_miles"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.practical_miles}
                            value={validation?.values?.practical_miles}
                            handleChange={validation.handleChange}
                        />
                        <div className="grid grid-cols-1 " >
                            <FileUploader validation={validation} setModalPrev={setModalPrev} deleteItem={deleteItem} />
                        </div>
                    </div>

                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Saving'}
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            {validation.values?.id ? 'Save' : 'Add new'}
                        </CustomButton>
                    </div>
                </form>

            </BaseModalAnimated >
            {modalStops.show &&
                <AddStopsModal modal={modalStops} setModal={setModalStops} validation={validation} />
            }

            <PreviewPDF modal={modalPrev} setModal={setModalPrev} />
        </>
    )
}
export default AddUpdLoadModal;