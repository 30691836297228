import { useNavigate } from "react-router-dom";

export const Columns = (modalConfirm, setModalConfirm) => {
    const navigate = useNavigate()
    let columns = [
        {
            id: 'no_settlement',
            name: 'NO.',
            selector: row => row.no_settlement
        },
        {
            id: 'operator',
            name: 'OPERATOR / CLIENT',
            selector: row => row.item_name
        },
        {
            id: 'type',
            name: 'TYPE',
            selector: row => row.type == 1 ? 'Company' : 'Operator'
        },
        {
            id: 'first_load',
            name: 'FIRST LOAD',
            selector: row => row.first_load
        },
        {
            id: 'last_load',
            name: 'LAST LOAD',
            selector: row => row.last_load
        },
        {
            id: 'created_by',
            name: 'CREATED BY',
            selector: row => row.created_by
        },
        {
            id: 'edited_by',
            name: 'EDITED BY',
            selector: row => row.edited_by
        },
        {
            id: 'action',
            name: 'ACTION',
            cell: row => (
                <>
                    <button onClick={() => navigate(`/settlement/detail/${row.id}`)} type="button" className={`flex rounded-full w-full border-r items-center justify-center px-2 py-1 hover:bg-gray-100 bg-[#ccf0eb]`}>
                        View Settlement &nbsp;&nbsp;&nbsp;
                    </button>
                </>
            ),
        },
        {
            id: 'action_two',
            name: '',
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => navigate(`/addSettlements/${row.id}`)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: row.id })} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        },


    ];
    return columns;

}
