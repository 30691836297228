import React from 'react';
import { clientsResource } from '../../helpers/configRoutes/backend_helper';

const Columns = (setModalConfirm,modalConfirm,openModalAdd) => {
    
    let columns = [
        {
            id: 'company_name',
            name: 'Company / Name',
            selector: row => row.company_name,
        },
        {
            id: 'contact_name',
            name: 'Contact / Name',
            selector: row => row.contact_name,
        },
        {
            id: 'address',
            name: 'Address',
            selector: row => row.address,
        },
        {
            id: 'email_billing',
            name: 'EMAIL BILLING',
            selector: row => row.email_billing,
        },
        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: client => (
                <div className="flex items-center space-x-2">
                    {/* Botón de edición */}
                    <button onClick={() => openModalAdd(client)} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* Botón de eliminar */}
                    <button onClick={() => setModalConfirm({ ...modalConfirm, show: true, id: client.id })} className={`flex items-center justify-center px-4 py-2 ${!client.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!client.can?.delete}>
                        <i className={`fas fa-trash ${!client.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        }


    ];
    return columns;
}

export default Columns;
