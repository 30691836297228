import React, { useState } from 'react';
import UpFile from './UpFile';

function FileUploader({ validation, setModalPrev, deleteItem }) {
    const [modal, setModal] = useState({ show: false, type: '', title: 'Upload your evidence' })
 
    return (
        <>
            <div className="grid grid-cols-1 gap-4">
                {/* Selector de tipo con íconos más pequeños */}
                <div className="flex space-x-4 justify-center">
                    <label className="cursor-pointer flex flex-col items-center text-center">
                        <div
                            className={`p-2 rounded-full ${(modal.type === 'pdf' && modal.show) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-blue-400`}
                            onClick={() => setModal({ ...modal, show: true, type: 'pdf' })}
                        >
                            <i className="fas fa-file-alt text-lg"></i>
                        </div>
                        <span className="text-xs mt-1">Documentos</span>
                    </label>
                    <label className="cursor-pointer flex flex-col items-center text-center">
                        <div
                            className={`p-2 rounded-full ${(modal.type === 'img' && modal.show) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} hover:bg-blue-400`}
                            onClick={() => setModal({ ...modal, show: true, type: 'img' })}
                        >
                            <i className="fas fa-image text-lg"></i>
                        </div>
                        <span className="text-xs mt-1">Imágenes</span>
                    </label>
                </div>

                {/* Lista de todos los archivos subidos */}
                <div className="grid grid-cols-1 bg-mts-gray w-full">
                    <table className="w-full text-left text-sm">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Nombre</th>
                                <th className="px-4 py-2">Tipo</th>
                                <th className="px-4 py-2">Descripción</th>
                                <th className="px-4 py-2">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {validation.values?.evidences?.map((f, i) => (
                                <tr key={i} className="border-b">
                                    <td className="px-4 py-2">{f.name}</td>
                                    <td className="px-4 py-2">{f.document_format}</td>
                                    <td className="px-4 py-2">{f.description}</td>
                                    <td className="px-4 py-2 flex space-x-2">
                                        <button
                                            type="button"
                                            onClick={() => setModalPrev({ show: true, url: f.base64 })}
                                            className="p-2 hover:bg-gray-100 rounded"
                                        >
                                            <i className="fas fa-eye text-mts-blue-primary"></i>
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => deleteItem('evidences', f)}
                                            className="p-2 hover:bg-gray-100 rounded"
                                        >
                                            <i className="fas fa-trash text-red-500"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <UpFile modal={modal} setModal={setModal} validation={validation} />
            </div>
        </>
    );
}

export default FileUploader;