export const Columns = (setModalFiles, modalFiles) => {
    let columns = [
        {
            id: 'order',
            name: 'WORK ORDER',
            selector: row => `${row.id}${row.client?.id}`,
        },
        {
            id: 'truck_triler',
            name: 'TRUCK TRAILER',
            selector: row => row.truck_triler,
        },
        {
            id: 'client',
            name: 'CLIENT',
            selector: row => row.client?.company_name,
        },
        {
            id: 'operator',
            name: 'OPERATOR',
            selector: row => row.operator_name,
        },
        {
            id: 'pick_up',
            name: 'PICK UP DATE',
            selector: row => row.pick_up_date,
        },
        {
            id: 'delivery_date',
            name: 'DELIVERY DATE',
            selector: row => row.delivery_date,
        },
        {
            id: 'evidences',
            name: 'EVIDENCES',
            cell: row => (
                <>
                    <button type="button" onClick={() => setModalFiles({ ...modalFiles, show: true, item: row })} className={`flex rounded-full w-full border-r items-center justify-center px-4 py-2 hover:bg-gray-100 bg-[#ccf0eb]`}>
                        Files
                    </button>
                </>
            ),
        },
    ];
    return columns;
}