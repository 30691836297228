import React, { useEffect, useState } from 'react';
import DataTableElement from '../../../components/elements/DataTableElement';
import CustomButton from '../../../components/form/CustomButton';
import CustomButtonOval from '../../../components/form/CustomButtonOval';
import { useParams } from 'react-router-dom';
import { paymentsResource } from '../../../helpers/configRoutes/backend_helper';
import { currencyUSD } from '../../../helpers/forms/formater';
import PreviewPDF from '../../../components/modal/PreviewPdf';

const PaymentDetail = () => {
    const { id } = useParams();
    const cols = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
        },
        {
            id: 'name',
            name: 'NAME',
            selector: row => row.client.contact_name,
        },
        {
            id: 'company',
            name: 'COMPANY',
            selector: row => row.client.name,
        },
        {
            id: 'payment_date',
            name: 'PAYMENT DATE',
            selector: row => row.created_at,
        },
        {
            id: 'total',
            name: 'PAYMENT',
            selector: row => currencyUSD(row.total),
        },
        {
            id: 'paymentMethod',
            name: 'PAYMENT METHOD',
            selector: row => row.paymentMethod.name,
        }
    ];

    const colsSecTable = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
        },
        {
            id: 'description',
            name: 'Description',
            selector: row => row.description,
        },
        {
            id: 'quantity',
            name: 'Quantity',
            selector: row => row.quanty,
        },
        {
            id: 'payment_date',
            name: 'Base Cost',
            selector: row => currencyUSD(row.base),
        },
        {
            id: 'total',
            name: 'Total Cost',
            selector: row => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.total),
        },
    ];

    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading accounts...' });
    const [data, setData] = useState([]);
    const [payment, setPayment] = useState([]);
    const [modalPdf,setModalPdf] = useState({show:false,url:''})

    useEffect(() => {
        const getPayments = async () => {
            setTableLoad({ ...tableLoad, show: true });
            const response = await paymentsResource('show', {}, id);
            setData([response.payment]);
            setPayment(response.payment);
            setTableLoad({ ...tableLoad, show: false });
        };
        getPayments();
    }, []);

    const getPdfDetailFile = async(email) =>{
        const response = await paymentsResource('show', {params:{pdf:true,sendEmail:email}}, id);
        if(!email){
            setModalPdf({show:true,url:response.pdf})
        }
    }
    

    return (
        <>
            <div className="p-4 md:p-8">
                <div className="text-xl md:text-2xl font-semibold mb-4">Invoice</div>
                <div className="w-full mx-auto p-4 md:p-8 bg-white rounded-lg shadow-lg border border-blue-300 max-h-max">
                    {/* Header Section */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4 mb-6">
                        <div>
                            <h3 className="font-semibold mb-3">Invoice From :</h3>
                            <p>M. Sendero</p>
                            <p>9694 Krajcik Locks Suite 635</p>
                        </div>
                        <div>
                            <h3 className="font-semibold mb-3">Invoice To :</h3>
                            <p>{payment.client?.name}</p>
                            <p>{payment.client?.contact_name}</p>
                            <p>{payment.client?.email}</p>
                        </div>
                        <div>
                            <p className="mb-2">Invoice Date : {payment.created_at}</p>
                            <p>Due Date : {payment.created_at}</p>
                        </div>
                    </div>

                    {/* Main Table */}
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                    <div className="p-4 md:p-10">
                        <DataTableElement columns={colsSecTable} items={data} tableLoad={tableLoad} classHead={'bg-mts-table-h-secondary'} />
                    </div>

                    {/* Total and Action Buttons */}
                    <div className="flex flex-col md:flex-row justify-between items-end w-full mt-8">
                        <p className="font-semibold text-lg md:text-xl mb-4 md:mb-0">Total = {currencyUSD(payment.total)}</p>
                        <div className="flex space-x-2">
                            <CustomButtonOval loading={tableLoad.show} textLoad={tableLoad.textLoad} className="px-4 py-2 rounded-lg text-mts-gray-hover bg-mts-gray-hover hover:bg-gray-400 inline-flex items-center" onClick={()=>getPdfDetailFile()}>
                                <i className="fa fa-print" aria-hidden="true"></i>
                            </CustomButtonOval>
                            <CustomButtonOval loading={tableLoad.show} textLoad={tableLoad.textLoad} className="px-4 py-2 rounded-lg hover:bg-blue-700 inline-flex items-center" onClick={()=>getPdfDetailFile(true)}>
                                {'Send'}
                                <i className="fa fa-paper-plane ml-2" aria-hidden="true"></i>
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
            </div>
            <PreviewPDF modal={modalPdf} setModal={setModalPdf}/>
        </>
    );
};

export default PaymentDetail;
