import { currencyUSD } from '../../helpers/forms/formater';
const ColumnsSubscriptions = () => {
    let columns = [
        {
            id: 'name',
            name: 'Company Name',
            selector: row => row.name,
        },
        {
            id: 'address',
            name: 'Location',
            selector: row => row.address,
        },
        {
            id: 'created_at',
            name: 'Active Since Date - Time',
            selector: row => row.created_at,
        },
        {
            id: 'trucks',
            name: 'Trucks',
            selector: row => row.lastSubs?.plan?.trucks,
        },
        {
            id: 'payment_total',
            name: 'Subscription',
            selector: row => currencyUSD(row.payment_total),
        },
        {
            id: 'plan_name',
            name: 'Subscription Type',
            selector: row => row.plan_name,
        },
    ];
    return columns;
}

export default ColumnsSubscriptions;