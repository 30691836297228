import { toast } from "react-toastify";

export const warningToast = (mensaje,position='top-right',autoClose='5000',draggable=true) => {
    toast.warn(
        mensaje, 
        {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: draggable,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const successToast = (mensaje,position='top-right',autoClose='5000',draggable=true) => {
    toast.success(
        mensaje, 
        {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: draggable,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const warningToastCenter = (mensaje,position='top-right',autoClose='5000',draggable=true) => {
    toast.warn(
        mensaje, 
        {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: draggable,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const dangerToast = (mensaje,position='top-right',autoClose='5000',draggable=true) => {
    toast.error(
        mensaje, 
        {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: draggable,
        progress: undefined,
        theme:"colored"
        }
    );
}

export const infoToastCenter = (mensaje,position='top-right',autoClose='5000',draggable=true) => {
    toast.info(
        mensaje, 
        {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: draggable,
        progress: undefined,
        theme:"colored"
        }
    );
}