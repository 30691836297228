import React, { useEffect, useState } from "react";
import FiltersPagination from "../../components/elements/FiltersPagination";
import { clientsResource, getDataHome, paymentsResource, plansResource } from "../../helpers/configRoutes/backend_helper";
import ColumnsSubscriptions from "./ColumnsSubscriptions";
import DataTableElement from "../../components/elements/DataTableElement";
import DatePicker from "react-datepicker";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"; // Import Recharts components
import { currencyUSD, currencyDecimal } from "../../helpers/forms/formater";

const Home = () => {
    const date = new Date();
    const [filtersPayment, setFiltersPayment] = useState({ month: date, home: true });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading subscriptions...' });
    const [tableDataLoad, setDataLoad] = useState(false);
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [subscriptions, setSubscriptions] = useState([]);

    const [filters, setFilters] = useState({ perPage: 10, page: 1, search: '' });

    // Sample data for the chart
    const baseChart = [{ name: '0K', sales: 0 }]
    const [chartData, setChartData] = useState([{ name: '0K', sales: 0 }]);
    //Total estad
    const [dataHome, setDataHome] = useState([])
    useEffect(() => {
        const getPayments = async () => {
            try {
                const response = await paymentsResource('get', {
                    params: filtersPayment,
                });

                // Supongamos que la API devuelve los datos ya procesados por día
                setChartData(response.chartData);
            } catch (error) {
                console.error("Error fetching payment data:", error);
            }
        };

        getPayments();
    }, [filtersPayment]);

    useEffect(() => {
        const getClients = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await clientsResource('get', { params: filters });
            //setSubscriptions(response.companies);
            //setTableLoad({ ...tableLoad, show: false })
        };
        getClients();
    }, [filters]);

    useEffect(() => {
        const getHomeInfo = async () => {
            setDataLoad(true)
            const response = await getDataHome();
            setDataHome(response);
            setDataLoad(false)
        };
        getHomeInfo();
    }, []);

    const cols = ColumnsSubscriptions();
    const changeDate = (e) => {
        console.log(e, 'datae')
    };

    //Datepik
    const renderMonthContent = (month, shortMonth, longMonth, day) => {
        const fullYear = new Date(day).getFullYear();
        const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
        return <span title={tooltipText}>{shortMonth}</span>;
    };

    return (
        <>
            <div className="p-4 sm:p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <h1 className="text-xl sm:text-2xl font-bold mb-6">Dashboard</h1>

                {/* Statistics Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total New Users</div>
                        <div className="text-3xl font-bold">{dataHome.users?.total}</div>
                        <div className="text-red-500 mt-2 text-sm">
                            <i className={`fas ${dataHome.users?.percentageChange > 0 ? 'fa-chart-line' : 'fa-arrow-down'}`}></i> {dataHome.users?.percentageChange}% from yesterday
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Active Subscriptions</div>
                        <div className="text-3xl font-bold">{dataHome.subscriptions?.total}</div>
                        <div className="text-red-500 mt-2 text-sm">
                            <i className={`fas ${dataHome.subscriptions?.percentageChange > 0 ? 'fa-chart-line' : 'fa-arrow-down'}`}></i> {dataHome.subscriptions?.percentageChange}% from past week
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total Sales</div>
                        <div className="text-3xl font-bold">{currencyUSD(dataHome.sales?.total)}</div>
                        <div className="text-red-500 mt-2 text-sm">
                            <i className={`fas ${dataHome.sales?.percentageChange > 0 ? 'fa-chart-line' : 'fa-arrow-down'}`}></i> {currencyDecimal(dataHome.sales?.percentageChange)}% from yesterday
                        </div>
                    </div>
                    <div className="bg-white shadow-md p-4 rounded-lg text-center">
                        <div className="text-gray-500 text-sm">Total Trucks (EXAMPLE)</div>
                        <div className="text-3xl font-bold">0</div>
                        <div className="text-green-500 mt-2 text-sm">
                            <i className="fas fa-chart-line"></i> 0% Up from yesterday (EXAMPLE)
                        </div>
                    </div>
                </div>

                {/* Sales Details */}
                <div className="bg-white shadow-md p-6 rounded-lg mb-6">
                    <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold mb-2 sm:mb-0">Sales Details</h2>
                        <DatePicker
                            selected={new Date()}
                            onChange={(e) => setFiltersPayment({ ...filtersPayment, month: e })}
                            selected={filtersPayment.month}
                            renderMonthContent={renderMonthContent}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                            className="p-2 bg-gray-100 rounded-lg"
                        />
                    </div>

                    {/* Chart */}
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* Active Subscriptions */}
                <div className="bg-white shadow-md p-6 rounded-lg">
                    <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold mb-2 sm:mb-0">Active Subscriptions</h2>
                    </div>
                    <div className="overflow-x-auto">
                        <FiltersPagination filters={filters} setFilters={setFilters} meta={meta} showFilters={true}>
                            <DataTableElement columns={cols} items={subscriptions} tableLoad={tableLoad} />
                        </FiltersPagination>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
