import React, { useState } from 'react';
import { dangerToast, warningToast } from '../../helpers/Toast'

const FileInput = ({ className,onChange, format, nameInput = 'Edit Photo ', withClass = 'w-full', textButton = true,validation,name }) => {
  const [fileName, setFileName] = useState('');

  // Función para validar el formato del archivo
  const validateFormat = (file) => {
    if (format === 'pdf') {
      return file.type === 'application/pdf';
    } else if (format === 'img') {
      return file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg';
    } else if (format == 'xml') {
      return file.type === 'text/xml';
    } 

    return false;
  };

  // Función para validar el tamaño del archivo
  const validateSize = (file) => {
    if (file.size <= 1024 * 1024) {
      return true
    }

    return false
  };


  const handleFileChange = async (archivo) => {
    const file = archivo.target.files[0];

    // Verificar si el archivo es de un formato permitido
    if (!validateFormat(file)) {
      dangerToast('You can only select files format: ' + (format == 'img' ? 'png, jpeg, jpg' : format));
      return;
    }

    // Verificar el tamaño del archivo
    if (!validateSize(file)) {
      dangerToast('Files up to 1MB');
      return;
    }

    setFileName(file.name);

    try {
      const base64 = await getBase64(file);
      const formato = file.type
      validation.setFieldValue(`name_file_input`, file.name)
      validation.setFieldValue(`${name}_format`, formato)
      validation.setFieldValue(name, base64)
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  }

  const getBase64 = (archivo) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className={`flex items-center justify-center ${withClass}`}>
      <label className="flex flex-col items-center w-full">
        <span className={`px-4 py-2 bg-o2o-gris rounded-full shadow-lg cursor-pointer hover:bg-o2o-gris-hover ${className}`}>
          {fileName ? (textButton ? fileName:nameInput) : nameInput}
        </span>
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};

export default FileInput;