import React from 'react';
import { useNavigate } from 'react-router-dom';

const Columns = () => {
    const navigate = useNavigate()
    let columns = [
        {
            id: 'id',
            name: 'ID',
            selector: row => row.id,
        },
        {
            id: 'name',
            name: 'NAME',
            selector: row => row.name,
        },
        {
            id: 'alias',
            name: 'Alias',
            selector: row => row.alias,
        },
        {
            id: 'card',
            name: 'Card',
            selector: row => row.card,
        },
        {
            id: 'actions',
            name: '',
            button: true,
            //Aqui van botones como eliminar o editar
            cell: row => (
                <div className="flex items-center space-x-2">
                    {/* edit button */}
                    <button onClick={() => console.log('Editar')} className="flex items-center justify-center px-4 py-2 border-r hover:bg-gray-100">
                        <i className="fas fa-pen text-gray-500"></i>
                    </button>
                    {/* delete button */}
                    <button onClick={() => console.log('Eliminar')} className={`flex border-r items-center justify-center px-4 py-2 ${!row.can?.delete ? '' : 'hover:bg-gray-100'}`} disabled={!row.can?.delete}>
                        <i className={`fas fa-trash ${!row.can?.delete ? 'opacity-50' : 'text-red-500'}`}></i>
                    </button>
                </div>
            ),
        }
    ];
    return columns;
}

export default Columns;