import React, { useEffect, useState } from "react";
import CustomButton from "../../components/form/CustomButton";
import FiltersPagination from "../../components/elements/FiltersPagination";
import DataTableElement from "../../components/elements/DataTableElement";
import { Columns } from "./Columns";
import { Navigate, useNavigate } from "react-router-dom";
import { settlementsResource } from "../../helpers/configRoutes/backend_helper";
import ConfirmDeleteModal from "../../components/modal/ConfirmDeleteModal";

const Settlement = () => {
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '', from: 0, to: 0, page: 1 });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading settlements...' });
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Truck', loadig: false, textLoad: 'Processing' })
    const cols = Columns(modalConfirm, setModalConfirm);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await settlementsResource('get', { params: filters });
            setData(response.settlements);
            setMeta(response.meta);
            setTableLoad({ ...tableLoad, show: false })
        };
        getData();
    }, [filters]);

    const deleteTrailerTruck = async () => {
        setModalConfirm({ ...modalConfirm, loading: true })
        const response = await settlementsResource('del', {}, modalConfirm.id);
        if (response.status) {
            setFilters({ ...filters })
        }
        setModalConfirm({ ...modalConfirm, loading: false, show: false, id: null })
    }

    return (
        <>
            <div className="p-8">
                <div className="text-2xl font-semibold mb-4">Settlement</div>
                <div className="flex justify-end font-semibold mb-4">
                    <CustomButton onClick={() => navigate('/addSettlements')} className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                        + Create New Settlement
                    </CustomButton>
                </div>
                <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'Settlement'}>
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                </FiltersPagination>
                <ConfirmDeleteModal modal={modalConfirm} setModal={setModalConfirm} action={deleteTrailerTruck} />
            </div>

        </>
    );
}

export default Settlement;