import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileAccountForm from "./ProfileAccountForm";

const ProfileAccounting = () => {
    const navigate = useNavigate()
    const [modal, setModal] = useState({ show: false,title:'Edit Profile',profile:true })
    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h2 className="text-lg font-bold mb-4">Account & Profile</h2>
            <div className="space-y-4 bg-white  p-4 h-full">
                <button className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600" onClick={() => setModal({...modal,show:true,profile:true})}>
                    Profile
                </button>
                <button className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600" onClick={() => setModal({...modal,show:true,profile:false})}>
                    Account
                </button>
            </div>
            <ProfileAccountForm modal={modal} setModal={setModal} />
        </div>
    );
}
export default ProfileAccounting;
