import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Menu() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.Login.user);
    const [menuItems, setMenuItems] = useState([]);
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar el menú hamburguesa

    useEffect(() => {
        if (user.id) {
            setMenuItems(user.models);
        }
    }, [user]);

    // Función para alternar el menú en pantallas pequeñas
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="relative flex flex-col h-screen">
            
            {/* Menú lateral (visible en pantallas grandes o cuando el menú está abierto) */}
            <div
                className={`${
                    isMenuOpen ? 'block' : 'hidden'
                } lg:block flex-col h-full min-w-60 max-w-60 border border-mts-white bg-mts-white lg:flex`}
            >
                <div className="flex flex-col">
                    {menuItems.map((item, index) => {
                        const isActive =
                            location.pathname === item.link ||
                            (item.associatedRoutes &&
                                item.associatedRoutes.includes(location.pathname));

                        return (
                            <Link
                                to={item.link}
                                key={index}
                                className={`relative flex flex-row justify-between items-center px-4 py-2 hover:bg-o2o-gray-alt hover:underline hover:text-base rounded-lg transition-colors duration-200 ${
                                    isActive
                                        ? 'bg-blue-500 text-white before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-blue-700'
                                        : ''
                                }`}
                            >
                                <div className="w-10">
                                    <span className="material-symbols-outlined">
                                        {item.icon}
                                    </span>
                                </div>
                                <div className="flex-1">{item.name}</div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
