import React, { useEffect, useState } from "react"
import BaseModalAnimated from "./BaseModalAnimated";
import CustomButton from "../form/CustomButton";

const PreviewPDF = ({ modal, setModal }) => {
    return (
        <BaseModalAnimated setModal={setModal} modal={modal}>
            <div className="flex flex-col items-center justify-center p-6 rounded-b border-slate-200">
                <iframe
                    src={`${modal.url}`}
                    allowFullScreen
                    style={{
                        overflow: "auto",
                        height: "500px",
                        width: "100%",
                    }}
                ></iframe>

                <CustomButton
                    type={"button"}
                    onClick={() => setModal({ ...modal, show: false })}
                    className="mt-4 w-full lg:w-[30%] bg-mts-red hover:bg-mts-red-hover"
                >
                    {'Cancel'}
                </CustomButton>
            </div>

        </BaseModalAnimated>

    );
};

export default PreviewPDF;