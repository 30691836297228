import React, { useEffect, useState } from "react";
import { getPrefixes, usersResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/form/CustomInput";
import CustomSelect from "../../components/form/CustomSelect";
import { showFormErrors } from "../../helpers/showFormErrors";
import FileInput from "../../components/form/FileInput";
import CustomButton from "../../components/form/CustomButton";
import { validatePhones } from "../../helpers/forms/Validation";

const AddUpdOperator = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Obtener ID si estamos editando
    const [prefixes, setPrefixes] = useState([]);
    const [loading, setLoading] = useState(false);

    // Obtener usuario y asignar valores al formulario
    useEffect(() => {
        const getUser = async () => {
            const response = await usersResource('show', {}, id);
            if (response.status) {
                Object.keys(response.user).forEach(field => {
                    validation.setFieldValue(field, response.user[field], false); // Setear valores en el formik sin disparar validación
                });
            }
        };
        if (id) {
            getUser();
        }
    }, [id]);

    // Obtener catálogo de prefijos
    useEffect(() => {
        const getPrefixesCatalog = async () => {
            const response = await getPrefixes();
            if (response.status) {
                setPrefixes(response.prefixes);
            }
        };
        getPrefixesCatalog();
    }, []);

    // Formik para manejo del formulario y validaciones
    const validation = useFormik({
        initialValues: {
            id: null,
            active: true,
            email: '',
            first_name: '',
            last_name: '',
            role: 'Operator',
            password: '',
            phone: '',
            prefix_id: '',
            company_id: '',
            profile_id: 3,
            imageProfile: '',
            payment_mile: '',
            driver_license: '',
            license_expiration: '',
            dot_physical_certificate: '',
            dot_physical_expiration: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Email is required'),
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            role: Yup.string().required('Role is required'),
            phone: validatePhones(true),
            prefix_id: Yup.string().required('Prefix is required'),
            payment_mile: Yup.string().required('Payment per mile is required'),
            password: Yup.string()
                .required("Password is required")
                .min(8, "Password must have at least 8 characters")
                .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
                .matches(/[a-z]/, "Password must contain at least one lowercase letter")
                .matches(/[0-9]/, "Password must contain at least one number"),
            driver_license: Yup.string().required('Driver license is required'),
            license_expiration: Yup.date()
                .min(new Date(), 'License expiration must be a future date')
                .required('License expiration is required'),
            dot_physical_certificate: Yup.string().required('DOT Physical certificate is required'),
            dot_physical_expiration: Yup.date()
                .min(new Date(), 'DOT Physical expiration must be a future date')
                .required('DOT Physical expiration is required'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true);
            const response = await usersResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                navigate('/workload'); // Navegar si la respuesta es exitosa
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false);
        },
    });

    return (
        <>
            <div className="p-8 bg-gray-100 min-h-screen">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">{id ? 'Update ' : 'Add New '}Operator</h1>
                </div>

                {/* Form Container */}
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        showFormErrors(validation);
                        return false;
                    }}
                >
                    <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
                        {/* Profile Picture */}
                        <div className="flex justify-center items-center mb-6">
                            <div className="relative flex flex-col items-center">
                                <img
                                    className="w-24 h-24 rounded-full mb-3"
                                    src={validation.values.imageProfile || '/images/usrAnonimo.png'}
                                    alt="Profile"
                                />
                                <FileInput format={'img'} name={'imageProfile'} validation={validation} />
                            </div>
                        </div>
                        {/* Form Fields */}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <CustomInput
                                label="First Name"
                                type="text"
                                name="first_name"
                                labelClassName={'text-base'}
                                error={validation?.errors?.first_name}
                                value={validation?.values?.first_name}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Last Name"
                                type="text"
                                name="last_name"
                                labelClassName={'text-base'}
                                error={validation?.errors?.last_name}
                                value={validation?.values?.last_name}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Email"
                                type="text"
                                name="email"
                                labelClassName={'text-base'}
                                error={validation?.errors?.email}
                                value={validation?.values?.email}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Password"
                                type="password"
                                name="password"
                                labelClassName={'text-base'}
                                error={validation?.errors?.password}
                                value={validation?.values?.password}
                                handleChange={validation.handleChange}
                            />
                            <CustomSelect
                                label="Phone Prefix"
                                options={prefixes}
                                name="prefix_id"
                                labelClassName={'text-base'}
                                error={validation?.errors?.prefix_id}
                                value={validation?.values?.prefix_id}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Phone Number"
                                type="text"
                                name="phone"
                                labelClassName={'text-base'}
                                error={validation?.errors?.phone}
                                value={validation?.values?.phone}
                                handleChange={validation.handleChange}
                            />
                            <CustomSelect
                                label="Status"
                                options={[{ id: 1, name: 'Active' }, { id: 0, name: 'Suspended' }]}
                                name="active"
                                labelClassName={'text-base'}
                                error={validation?.errors?.active}
                                value={validation?.values?.active}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Payment per mile"
                                type="text"
                                name="payment_mile"
                                labelClassName={'text-base'}
                                error={validation?.errors?.payment_mile}
                                value={validation?.values?.payment_mile}
                                handleChange={validation.handleChange}
                            />
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <CustomInput
                                    label="Driver License Expiration"
                                    type="date"
                                    name="license_expiration"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.license_expiration}
                                    value={validation?.values?.license_expiration}
                                    handleChange={validation.handleChange}
                                />
                                <FileInput
                                    className={'bg-mts-gray-button'}
                                    validation={validation}
                                    format={'pdf'}
                                    name={'driver_license'}
                                    nameInput={'Driver License'}
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <CustomInput
                                    label="DOT Physical Expiration"
                                    type="date"
                                    name="dot_physical_expiration"
                                    labelClassName={'text-base'}
                                    error={validation?.errors?.dot_physical_expiration}
                                    value={validation?.values?.dot_physical_expiration}
                                    handleChange={validation.handleChange}
                                />
                                <FileInput
                                    className={'bg-mts-gray-button'}
                                    validation={validation}
                                    format={'pdf'}
                                    name={'dot_physical_certificate'}
                                    nameInput={'DOT Physical'}
                                />
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="flex mt-6 justify-center w-full">
                            <CustomButton
                                loading={loading}
                                textLoad={'Processing'}
                                type="submit"
                                className="w-1/4 bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
                            >
                                {id ? 'Save Changes' : 'Add Operator'}
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddUpdOperator;
