import React, { useEffect, useState } from "react";
import Columns from "./Columns";
import { paymentsMethodsResource } from "../../../helpers/configRoutes/backend_helper";
import DataTableElement from "../../../components/elements/DataTableElement";
import CustomButton from "../../../components/form/CustomButton";
import AddUpdMethodsModal from "./AddUpdMethodsModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const PaymentMethods = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '',from:0,to:0,page:1 });
    const [tableLoad, setTableLoad] = useState({show:false,textLoad:'Loading accounts...'});
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [data, setData] = useState([]);
    const [modalConfirm, setModalConfirm] = useState({ show: false, title: 'Delete Truck', loadig: false, textLoad: 'Processing' })
    const [modal, setModal] = useState({ show: false });
    const [item, setItem] = useState({ id: null });

    useEffect(() => {
        const getPayments = async () => {
            setTableLoad({ ...tableLoad, show: true });
            const response = await paymentsMethodsResource('get', {});
            setData(response.methods);
            setTableLoad({ ...tableLoad, show: false });
        };
        getPayments();
    }, [filters]);

    const openModalAdd = (item = {}) => {
        setItem(item);
        setModal({ ...modal, show: true, title: item.id ? "Edit Method" : 'Add Method' });
    };

    const cols = Columns();

    return (
        <div className="p-8">
            <div className="text-2xl font-semibold mb-4">Payment Methods</div>
            <div className="flex justify-end font-semibold mb-4">
                <CustomButton 
                    onClick={() => openModalAdd('')} 
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                >
                    + Add New Method
                </CustomButton>
            </div>

            <div className="bg-white shadow-md rounded-lg">
                <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
            </div>

            {/* Envuelve el modal con Elements */}
            <Elements stripe={stripePromise}>
                <AddUpdMethodsModal 
                    modal={modal} 
                    setModal={setModal} 
                    item={item} 
                    setFilters={setFilters} 
                    filters={filters} 
                />
            </Elements>
        </div>
    );
};

export default PaymentMethods;
