import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showFormErrors } from "../../../helpers/showFormErrors";
import { paymentsMethodsResource } from "../../../helpers/configRoutes/backend_helper";
import BaseModalAnimated from "../../../components/modal/BaseModalAnimated";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CustomInput from "../../../components/form/CustomInput";

const AddUpdMethodsModal = ({ modal, setModal, item, setFilters, filters }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const baseItem = {
        id: null,
        card_name: "",
        alias: "",
        principal: false
    };

    const validation = useFormik({
        initialValues: baseItem,
        validationSchema: Yup.object({
            card_name: Yup.string().required("El nombre en la tarjeta es obligatorio."),
            alias: Yup.string().optional(),
            principal: Yup.boolean(),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true);
        
            if (!stripe || !elements) {
                setErrors({ card_name: "Stripe no está disponible en este momento." });
                setLoading(false);
                return;
            }
        
            const cardElement = elements.getElement(CardElement);
        
            try {
                // Crear el PaymentMethod en Stripe
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: { name: values.card_name },
                });
        
                if (error) throw new Error(error.message);
        
                // Agregar el ID del PaymentMethod a los valores enviados al backend
                const dataToSend = {
                    ...values,
                    paymentMethodId: paymentMethod.id,
                };
        
                // Enviar los datos al backend
                const response = await paymentsMethodsResource(
                    values.id ? "put" : "post",
                    dataToSend,
                    values.id
                );
        
                if (!response.status) throw new Error(response.errors);
        
                setFilters({ ...filters, search: filters.search });
                setModal({ ...modal, show: false });
            } catch (err) {
                setErrors({ card_name: err.message });
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (item.id) {
            validation.setValues(item); // Actualizar valores del formulario con el item existente
        } else {
            validation.resetForm({ values: baseItem }); // Restablecer a valores iniciales
        }
    }, [item, modal]);

    return (
        <BaseModalAnimated modal={modal} setModal={setModal}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation);
                }}
            >
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="w-full md:w-1/2 px-2 mb-3">
                        <label>Nombre</label>
                        <input
                            type="text"
                            name="card_name"
                            placeholder="Nombre"
                            value={validation.values.card_name || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            className={` border pl-4 rounded-md h-12 bg-mts-gray ${validation.errors.card_name && "border-red-500"
                                }`}
                        />
                        {validation.errors.card_name && (
                            <p className="text-red-500 text-sm mt-1">
                                {validation.errors.card_name}
                            </p>
                        )}
                    </div>
                    <div className="w-full  px-2 mb-3">
                        <label>Información de la Tarjeta</label>
                        <CardElement className="w-full py-1.5 px-2.5 rounded border focus:outline-none focus:border-o2o-aqua-hover bg-stone-100 text-ellipsis" />
                    </div>
                    <div>
                        <CustomInput
                            label="Alias"
                            type="text"
                            name="alias"
                            labelClassName={'text-base'}
                            error={validation?.errors?.alias}
                            value={validation?.values?.alias}
                            handleChange={validation.handleChange}
                        />
                    </div>
                    <div>
                        <div className="w-full px-2 mb-3">
                            <label className="text-base font-medium">Is this the primary payment method?</label>
                            <div className="flex items-center gap-4 mt-2">
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="principal"
                                        value="true"
                                        checked={validation.values.principal === true}
                                        onChange={() => validation.setFieldValue("principal", true)}
                                        className="mr-2"
                                    />
                                    Sí
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="radio"
                                        name="principal"
                                        value="false"
                                        checked={validation.values.principal === false}
                                        onChange={() => validation.setFieldValue("principal", false)}
                                        className="mr-2"
                                    />
                                    No
                                </label>
                            </div>
                            {validation.errors.principal && (
                                <p className="text-red-500 text-sm mt-1">
                                    {validation.errors.principal}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                    <CustomButton
                        loading={loading}
                        textLoad={"Saving"}
                        type={"success"}
                        className="w-full lg:w-[30%]"
                    >
                        {validation.values.id ? "Save" : "Add new"}
                    </CustomButton>
                </div>
            </form>
        </BaseModalAnimated>
    );
};

export default AddUpdMethodsModal;
