import React, { useEffect, useState } from "react";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";
import CustomButton from "../../components/form/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getPrefixes, clientsResource, plansResource } from "../../helpers/configRoutes/backend_helper";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomSelect from "../../components/form/CustomSelect";
import { validatePhones } from "../../helpers/forms/Validation";

const AddUpdClientModal = ({ modal, setModal, clientItem, setFilters, filters }) => {
    const navigate = useNavigate()
    const [prefixes, setPrefixes] = useState([]);
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const baseitem = {
        id: null,
        company_name: '',
        contact_name: '',
        email: '',
        email_billing: '',
        address: '',
        net_payment: '',
        phone: '',
        prefix_id: '',
        company_id: ''
    }

    const validation = useFormik({
        initialValues: clientItem,
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email').required('Email is required'),
            email_billing: Yup.string().email('Invalid email').required('Email is required'),
            company_name: Yup.string().required('Name is required'),
            prefix_id: Yup.string().required('Prefix is required'),
            phone: validatePhones(true),
            contact_name: Yup.string().required('Contact name is required'),
            address: Yup.string().required('Address is required'),
            net_payment: Yup.string().required('Net Payment name is required'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            const response = await clientsResource(values.id ? 'put' : 'post', values, values.id);
            if (response.status) {
                setLoading(false)
                setFilters({ ...filters, search: filters.search })
                setModal({ ...modal, show: false })
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    useEffect(() => {
        if (clientItem.id) {
            validation.setValues(clientItem); // Actualizar los valores del formulario cuando `client` cambia
        } else {
            validation.resetForm({ values: baseitem }); // Resetea el formulario a los valores por defecto si no hay cliente
        }
    }, [modal]);

    useEffect(() => {
        const getPrefixesCatalog = async () => {
            const response = await getPrefixes();
            if (response.status) {
                setPrefixes(response.prefixes);
            }
        }
        getPrefixesCatalog();
    }, []);

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                <form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    showFormErrors(validation)
                    return false;
                }}>
                    <div className="grid grid-cols-2 gap-4 p-4">
                        <CustomInput
                            label="Company Name"
                            type="text"
                            name="company_name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.company_name}
                            value={validation?.values?.company_name}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Contact Name"
                            type="text"
                            name="contact_name"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.contact_name}
                            value={validation?.values?.contact_name}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Email Address"
                            type="text"
                            name="email"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.email}
                            value={validation?.values?.email}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Billing Email"
                            type="text"
                            name="email_billing"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.email_billing}
                            value={validation?.values?.email_billing}
                            handleChange={validation.handleChange}
                        />

                        <div className="grid grid-cols-2">
                            <CustomSelect
                                label="Phone Prefix"
                                options={prefixes}
                                name="prefix_id"
                                labelClassName={'text-base text-[#ADADAD]'}
                                className={'mr-2'}
                                error={validation?.errors?.prefix_id}
                                value={validation?.values?.prefix_id}
                                handleChange={validation.handleChange}
                            />
                            <CustomInput
                                label="Phone Number"
                                type="text"
                                name="phone"
                                labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                                error={validation?.errors?.phone}
                                value={validation?.values?.phone}
                                handleChange={validation.handleChange}
                            />
                        </div>

                        <CustomInput
                            label="Net Payment"
                            type="text"
                            name="net_payment"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.net_payment}
                            value={validation?.values?.net_payment}
                            handleChange={validation.handleChange}
                        />
                        <CustomInput
                            label="Address"
                            type="text"
                            name="address"
                            labelClassName="text-[14px] text-[#ADADAD] whitespace-nowrap"
                            error={validation?.errors?.address}
                            value={validation?.values?.address}
                            handleChange={validation.handleChange}
                        />

                    </div>

                    <div className="flex items-center justify-center p-6 rounded-b border-slate-200">
                        <CustomButton
                            loading={loading}
                            textLoad={'Saving'}
                            type={"success"}
                            className="w-full lg:w-[30%]"
                        >
                            {validation.values?.id ? 'Save' : 'Add new'}
                        </CustomButton>
                    </div>
                </form>
            </BaseModalAnimated >
        </>
    )
}
export default AddUpdClientModal;