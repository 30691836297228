import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FiltersPagination from "../../../components/elements/FiltersPagination";
import DataTableElement from "../../../components/elements/DataTableElement";
import { Columns } from "./Columns";
import CustomButton from "../../../components/form/CustomButton";
import { settlementsResource } from "../../../helpers/configRoutes/backend_helper";
import EvidencesFilesModal from "../../Loads/EvidencesFilesModal";
import PreviewPDF from "../../../components/modal/PreviewPdf";

const SettlementDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [filters, setFilters] = useState({ endDate: '', startDate: '', search: '', from: 0, to: 0, page: 1, truck: true, settlement: true });
    const [meta, setMeta] = useState({ total: 0, current_page: 0 });
    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading loads...' });
    const [data, setData] = useState([]);
    const [modalFiles, setModalFiles] = useState({ show: false, item: {}, title: 'Show files', });
    const cols = Columns(setModalFiles, modalFiles);
    const [modalPdf, setModalPdf] = useState({ show: false, url: '', title: 'Settlement Report' })
    const [loading, setLoading] = useState(false)
    const [settlement, setSettlement] = useState({})

    useEffect(() => {
        const getSettlement = async () => {
            setTableLoad({ ...tableLoad, show: true })
            const response = await settlementsResource('show', {}, id)
            setSettlement(response.settlement)
            setData(response.settlement.loads_data)
            setTableLoad({ ...tableLoad, show: false })
        };
        if (id) {
            getSettlement();
        }
    }, [id]);

    const getReport = async () => {
        const response = await settlementsResource('show', { params: { report: true } }, settlement.id)
        setModalPdf({ ...modalPdf, show: true, url: response.pdf_base64 })
    }

    return (
        <>
            <div className="flex items-center space-x-2 p-4">
                <button
                    onClick={() => navigate('/settlements')}
                    className="text-gray-600 hover:text-gray-800"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
                <h1 className="text-lg font-medium text-gray-800">Settlement No {settlement.no_settlement}</h1>

            </div>
            <div className="flex justify-end font-semibold mb-4">
                <CustomButton onClick={() => getReport()} loading={loading} textLoad={'Generating report'}>
                    {settlement.type == 1 ? 'Company settlement' : 'Operator settlement'}
                </CustomButton>
            </div>
            <FiltersPagination showFilters={true} filters={filters} setFilters={setFilters} meta={meta} statusModel={'Settlement'}>

                <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
            </FiltersPagination>
            <EvidencesFilesModal modal={modalFiles} setModal={setModalFiles} />
            <PreviewPDF modal={modalPdf} setModal={setModalPdf}/>
        </>
    );
};
export default SettlementDetail;
