import React, { useEffect, useState } from 'react';
import DataTableElement from '../../../components/elements/DataTableElement';
import CustomButton from '../../../components/form/CustomButton';
import CustomButtonOval from '../../../components/form/CustomButtonOval';
import { useParams } from 'react-router-dom';
import { paymentsResource, settlementsResource } from '../../../helpers/configRoutes/backend_helper';
import { currencyUSD } from '../../../helpers/forms/formater';
import PreviewPDF from '../../../components/modal/PreviewPdf';

const AccountSettlement = () => {
    const { id } = useParams();
    const [settlement,setSettlement]=useState({})
    const cols = [
        {
            id: 'id',
            name: 'NO SETTLEMENT',
            selector: row => row.no_settlement,
        },
        {
            id: 'company',
            name: settlement.type==1 ? 'COMPANY':'OPERATOR',
            selector: row => row.item_name,
        },
        {
            id: 'total_amount',
            name: 'TOTAL AMOUNT',
            selector: row => row.total_amount,
        },
    ];

    const [tableLoad, setTableLoad] = useState({ show: false, textLoad: 'Loading accounts...' });
    const [data, setData] = useState([]);
    const [payment, setPayment] = useState([]);
    const [modalPdf,setModalPdf] = useState({show:false,url:''})

    useEffect(() => {
        const getPayments = async () => {
            setTableLoad({ ...tableLoad, show: true });
            const response = await settlementsResource('show', {}, id);
            setData([response.settlement]);
            setSettlement(response.settlement);
            setTableLoad({ ...tableLoad, show: false });
        };
        getPayments();
    }, []);

    const getPdfDetailFile = async(email) =>{
        const response = await settlementsResource('show', {params:{report:true,invoice:true,email:email}}, id);
        if(!email){
            setModalPdf({show:true,url:response.pdf_base64})
        }
    }

    return (
        <>
            <div className="p-4 md:p-8">
                <div className="text-xl md:text-2xl font-semibold mb-4">Invoice</div>
                <div className="w-full mx-auto p-4 md:p-8 bg-white rounded-lg shadow-lg border border-blue-300 max-h-max">
                    {/* Header Section */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-4 mb-6">
                        <div>
                            <h3 className="font-semibold mb-3">Invoice From :</h3>
                            <p>M. Sendero</p>
                            <p>9694 Krajcik Locks Suite 635</p>
                        </div>
                        <div>
                            <h3 className="font-semibold mb-3">Invoice To :</h3>
                            <p>{settlement.item_name}</p>
                        </div>
                        <div>
                            <p className="mb-2">Invoice Date : {settlement.created_at}</p>
                            <p>Due Date : {settlement.created_at}</p>
                        </div>
                    </div>

                    {/* Main Table */}
                    <DataTableElement columns={cols} items={data} tableLoad={tableLoad} />
                    
                    {/* Total and Action Buttons */}
                    <div className="flex flex-col md:flex-row justify-between items-end w-full mt-8">
                        <p className="font-semibold text-lg md:text-xl mb-4 md:mb-0">Total = {currencyUSD(settlement.total_amount)}</p>
                        <div className="flex space-x-2">
                            <CustomButtonOval loading={tableLoad.show} textLoad={tableLoad.textLoad} className="px-4 py-2 rounded-lg text-mts-gray-hover bg-mts-gray-hover hover:bg-gray-400 inline-flex items-center" onClick={()=>getPdfDetailFile()}>
                                <i className="fa fa-print" aria-hidden="true"></i>
                            </CustomButtonOval>
                            <CustomButtonOval loading={tableLoad.show} textLoad={tableLoad.textLoad} className="px-4 py-2 rounded-lg hover:bg-blue-700 inline-flex items-center" onClick={()=>getPdfDetailFile(true)}>
                                {'Send'}
                                <i className="fa fa-paper-plane ml-2" aria-hidden="true"></i>
                            </CustomButtonOval>
                        </div>
                    </div>
                </div>
            </div>
            <PreviewPDF modal={modalPdf} setModal={setModalPdf}/>
        </>
    );
};

export default AccountSettlement;
