import React, { useEffect, useState } from "react";
import { fabricantsResource, getPrefixes, modelsTruckTrailerResource, usersResource, } from "../../helpers/configRoutes/backend_helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/form/CustomInput";
import { showFormErrors } from "../../helpers/showFormErrors";
import CustomButton from "../../components/form/CustomButton";
import BaseModalAnimated from "../../components/modal/BaseModalAnimated";

const AddCatalogsModal = ({ modal, setModal, validation,setModels,setFabricants }) => {
    const [loading, setLoading] = useState(false)

    // Formik para manejo del formulario y validaciones
    const validationCatalog = useFormik({
        initialValues: {
            id: null,
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
        }),
        onSubmit: async (values, { setErrors }) => {
            setLoading(true)
            let response ={}
            if(modal.title!='Fabricant'){
                values.fabricant_id = validation.values?.fabricant_id
                response = await modelsTruckTrailerResource( 'post', values, values.id);
                setModels(response.models)
                validation.setFieldValue('model_id',response.id)
                setModal({...modal,show:false})
            }else{
                response = await fabricantsResource('post', values, values.id);
                setFabricants(response.fabricants)
                validation.setFieldValue('fabricant_id',response.id)
                setModal({...modal,show:false})
            }
            
            if (response.status) {
                // Navegar al equipo si la respuesta es exitosa
            } else {
                setErrors(response.errors); // Establecer errores en caso de fallo
            }
            setLoading(false)
        }
    });

    return (
        <>
            <BaseModalAnimated modal={modal} setModal={setModal}>
                
                    {/* Form Container */}
                    <form onSubmit={e => {
                        e.preventDefault();
                        validationCatalog.handleSubmit();
                        showFormErrors(validationCatalog)
                        return false;
                    }}>
                        <div className="bg-white p-6 rounded-lg shadow-lg mx-auto">
                            {/* Profile Picture */}

                            {/* Form Fields */}
                            
                                {/* First Name */}
                                <div>
                                    <CustomInput
                                        label="Name"
                                        type="text"
                                        name="name"
                                        labelClassName={'text-base'}
                                        error={validationCatalog?.errors?.name}
                                        value={validationCatalog?.values?.name}
                                        handleChange={validationCatalog.handleChange}

                                    />
                                </div>

                         

                            {/* Submit Button */}
                            <div className="flex mt-6 justify-center w-full">
                                <CustomButton loading={loading} textLoad={'Processing'} type="submit" className="w-1/4 bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600">
                                    Save {modal.title}
                                </CustomButton>
                            </div>
                        </div>
                    </form>
            </BaseModalAnimated>
        </>
    )
}

export default AddCatalogsModal;
