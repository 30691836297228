import React from "react";
import { useNavigate } from "react-router-dom";

const TrailerTruck = () => {
    const navigate = useNavigate()
    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h2 className="text-lg font-bold mb-4">Trucks & Trailers</h2>
            <div className="space-y-4 bg-white  p-4 h-full">
                <button className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600" onClick={()=>navigate('/trucks')}>
                    Trucks
                </button>
                <button className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600" onClick={()=>navigate('/trailers')}>
                    Trailers
                </button>
            </div>
        </div>
    );
}

export default TrailerTruck;
