import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, LoadScript, Marker, Autocomplete } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "400px",
};

const GoogleMapsStops = ({ stops, setStops, validation }) => {
    const [mapCenter, setMapCenter] = useState(validation.values); // Centro actual del mapa
    const [currentStop, setCurrentStop] = useState(validation.values); // Parada actual seleccionada
    const autocompleteRef = useRef(null);

    // Calcula la distancia en millas entre dos coordenadas
    const calculateDistance = async (origin, destination) => {
        const service = new window.google.maps.DistanceMatrixService();
        return new Promise((resolve, reject) => {
            service.getDistanceMatrix(
                {
                    origins: [origin],
                    destinations: [destination],
                    travelMode: "DRIVING", // Asegúrate de que el modo sea "DRIVING"
                    unitSystem: window.google.maps.UnitSystem.METRIC, // Cambiar a "IMPERIAL" para millas si lo prefieres
                    avoidHighways: false, // Opcional: Evitar autopistas
                    avoidTolls: false, // Opcional: Evitar peajes
                },
                (response, status) => {
                    if (status === "OK" && response.rows[0].elements[0].distance) {
                        const distanceInMeters = response.rows[0].elements[0].distance.value;
                        const distanceInMiles = (distanceInMeters / 1609.34).toFixed(2); // Convert to miles
                        resolve(distanceInMiles);
                    } else {
                        reject("Error calculating distance");
                    }
                }
            );
        });
    };

    const handlePlaceChanged = async () => {
        const place = autocompleteRef.current.getPlace();
        validation.setFieldValue('name', place.formatted_address)
        if (place.geometry) {
            const newCoordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };

            validation.setFieldValue("lat", newCoordinates.lat);
            validation.setFieldValue("lng", newCoordinates.lng);

            setCurrentStop((prev) => ({
                ...prev,
                lat: newCoordinates.lat,
                lng: newCoordinates.lng,
            }));
            setMapCenter(newCoordinates); // Centra el mapa en la nueva ubicación

            // Buscar el stop anterior según el campo `order`
            const currentOrder = validation.values.order; // El `order` actual
            const previousStop = stops.find((stop) => stop.order === currentOrder - 1);

            if (previousStop) {
                const origin = new window.google.maps.LatLng(previousStop.lat, previousStop.lng);
                const destination = new window.google.maps.LatLng(newCoordinates.lat, newCoordinates.lng);

                try {
                    const distance = await calculateDistance(origin, destination);
                    console.log(`Distance to previous stop: ${distance} miles`);
                    validation.setFieldValue('mile', distance)
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.log("No previous stop found. Setting miles to 0.");
                validation.setFieldValue("mile", 0); // Si no hay `stop` anterior, establece millas en 0
            }
        }
    };


    const handleMapClick = async (event) => {
        const newCoordinates = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };

        setCurrentStop((prev) => ({
            ...prev,
            lat: newCoordinates.lat,
            lng: newCoordinates.lng,
        }));

        setMapCenter(newCoordinates); // Centra el mapa en el nuevo marcador
        validation.setFieldValue("lat", newCoordinates.lat);
        validation.setFieldValue("lng", newCoordinates.lng);

        // Buscar el stop anterior según el campo `order`
        const currentOrder = validation.values.order; // El `order` actual
        const previousStop = stops.find((stop) => stop.order === currentOrder - 1);

        if (previousStop) {
            const origin = new window.google.maps.LatLng(previousStop.lat, previousStop.lng);
            const destination = new window.google.maps.LatLng(newCoordinates.lat, newCoordinates.lng);

            try {
                const distance = await calculateDistance(origin, destination);
                console.log(`Distance to previous stop: ${distance} miles`);
                validation.setFieldValue('mile', distance)
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log("No previous stop found. Setting miles to 0.");
            validation.setFieldValue("mile", 0); // Si no hay `stop` anterior, establece millas en 0
        }
    };


    return (
        <LoadScript googleMapsApiKey="AIzaSyD2ihNBZrNGhWURi4BSk53T5lsgrJ08ciA" libraries={["places"]}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={10}
                onClick={handleMapClick} // Permite mover el marcador al hacer clic
            >
                {stops.map((stop, index) => (
                    <Marker
                        key={index}
                        position={{ lat: stop.lat, lng: stop.lng }}
                        label={{
                            text: `${stop.order}`, // Muestra el número según el orden
                            color: "white", // Color del texto
                            fontSize: "14px", // Tamaño de la etiqueta
                        }}
                    />
                ))}

                {/* Marker de la parada actual */}
                {currentStop && <Marker
                    position={{ lat: currentStop.lat, lng: currentStop.lng }}
                    label={{
                        text: "?", // Marcador actual (puedes ajustarlo según tus necesidades)
                        color: "red", // Color del texto
                        fontSize: "14px",
                    }}
                />}
            </GoogleMap>

            <div className="mt-4">
                <label className="font-normal text-sm text-[14px] text-[#ADADAD] whitespace-nowrap">Address</label>
                <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={handlePlaceChanged}>
                    <input
                        type="text"
                        placeholder="Enter a location"
                        className="p-2 border border-gray-300 rounded w-full"
                        onChange={(e) => validation.setFieldValue('name', e.target.value)}
                    />
                </Autocomplete>
            </div>
        </LoadScript>
    );
};

export default GoogleMapsStops;
