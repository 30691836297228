import React from 'react';

const DataTableElement = ({ columns, items, tableLoad, classHead }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto w-full">
        <thead className={`bg-mts-table-h text-gray-600 border-b ${classHead}`}>
          <tr>
            {columns.map((col) => (
              <th key={col.id} className="p-4 text-left text-sm md:text-base">
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-mts-table-b text-gray-600">
          {tableLoad.show ? (
            <tr>
              <td colSpan={columns.length} className="p-4 text-center">
                <div className="flex justify-center items-center">
                  <span className="loader"></span> {/* Aquí puedes agregar tu componente de carga */}
                  <svg
                    className="animate-spin h-5 w-5 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="ml-2">{tableLoad.textLoad ?? 'Loading...'}</span>
                </div>
              </td>
            </tr>
          ) : items.length ? (
            items.map((item) => (
              <tr key={item.id} className="border-b">
                {columns.map((col) => (
                  <td key={col.id} className="p-4 text-sm md:text-base">
                    {col.cell ? col.cell(item) : col.selector(item)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="p-4 text-center">
                <div className="flex justify-center items-center">
                  <span className="loader"></span> {/* Aquí puedes agregar tu componente de carga */}
                  <span className="ml-2">{'No items found to list...'}</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

  );
};

export default DataTableElement;
