import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SingleCalendarDateRangePicker = ({ filters, setFilters, setShowCalendar, showCalendar }) => {
  const [startDate, setStartDate] = useState(filters.startDate ?? null);
  const [endDate, setEndDate] = useState(filters.endDate ?? null);

  // Temporary state for local changes before applying
  const [tempStartDate, setTempStartDate] = useState(filters.startDate ?? null);
  const [tempEndDate, setTempEndDate] = useState(filters.endDate ?? null);

  const handleDateChange = (date) => {
    if (!tempStartDate) {
      // If no start date is set, set the start date
      setTempStartDate(date);
    } else if (!tempEndDate && date > tempStartDate) {
      // If a start date is set and the selected date is after it, set it as the end date
      setTempEndDate(date);
    } else {
      // Reset selection if both dates are already selected or the selected date is before the start date
      setTempStartDate(date);
      setTempEndDate(null);
    }
  };

  const handleApply = () => {
    // Update the filters with selected dates
    setFilters({ ...filters, startDate: tempStartDate, endDate: tempEndDate });
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setShowCalendar(false); // Hide calendar after applying
  };

  const handleCancel = () => {
    // Reset local changes and close the calendar
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setShowCalendar(false); // Hide calendar on cancel
  };

  return (
    <div className='z-1'>
      <DatePicker
        selected={tempStartDate} // Show the start date as selected
        onChange={handleDateChange}
        selectsRange={false} // Disable selectsRange for single date selection mode
        startDate={tempStartDate} // Start of the range
        endDate={tempEndDate} // End of the range
        inline
        dateFormat="yyyy/MM/dd"
        dayClassName={(date) =>
          tempStartDate && tempEndDate && date >= tempStartDate && date <= tempEndDate
            ? 'bg-blue-200'
            : undefined
        }
      />
      {/* Buttons for Apply and Cancel */}
      <div className="flex space-x-2 mt-4">
        <button
          className="bg-mts-blue-primary text-sm text-white px-4 py-2 rounded hover:bg-mts-blue-hover"
          onClick={handleApply}
        >
          Apply
        </button>
        <button
          className="bg-gray-300 text-sm text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SingleCalendarDateRangePicker;
