import React, { useEffect } from "react";
import { dangerToast, warningToast } from "../../helpers/Toast";

const IconAlert = ({days,warningDays,dangerDays,textAlert}) => {
    useEffect(() => {
        const getAletrs = async () => {
            if(days <= (dangerDays || 3)){
                return dangerToast(textAlert, 'bottom-right', false, false)
            }
            return warningToast(textAlert, 'bottom-right', false, false)
        }
        console.log(days )
        if(days <= (warningDays || 7)){
            getAletrs()    
        }
    }, []);

    return (
        <>
            {days <= (warningDays || 7) && (
                <i
                    className={`fas fa-exclamation-triangle ${days <= (dangerDays || 3) ? 'text-mts-danger' : 'text-mts-warning'} text-lg`}
                    title="Driver License is about to expire!"
                ></i>
            )}
        </>
    )
}

export default IconAlert